export const coreRoutesNames = {
	ROOT: '',
	MAIN: 'app',
	AUTH: 'auth',
	ADMIN: 'admin',
	DASHBOARD: 'dashboard',
	EMPLOYEES: 'employees',
	HELP: 'help',
	RESIDENCES: 'residences',
	NOTIFICATIONS: 'notifications',
	ADMINISTRATORS: 'administrators',
	COUNTRIES: 'countries',
	DOCUMENTS: 'documents'
};
