import {AdminResult} from './admin-result';

export class Country {
	id: number;
	code: string;
	label: string;
	is_active: boolean;
	is_in_schengen: boolean;
	admins: AdminResult[];
	has_other_ships: boolean;
	has_other_residences: boolean;
	
	constructor(data = {}) {
		Object.assign(this, data);
	}
}
