import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from '@ff/auth';
import { UserService } from '@ff/user';
import { TranslateService } from '@ngx-translate/core';
import {Country} from 'src/app/models/country';
import { Perimeter } from 'src/app/models/perimeter';
import { User } from 'src/app/models/user';
import { DialogPerimeterComponent } from 'src/app/modules/shared/components/dialog-perimeter/dialog-perimeter.component';
import { CrudService } from './../../../../services/shared/crud.service';
import { LanguagesService } from './../../../../services/shared/languages.service';

@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
	form: FormGroup;
	authUser: User;
	perimeters: Perimeter[] = [];
	adminCountries: Country[] = [];
	selectedPerimeterNames: string[];
	selectedPerimeterIds: number[];
	selectedCountryId: number;

	constructor(
		private authService: AuthService,
		private crudService: CrudService,
		private translateService: TranslateService,
		private languagesService: LanguagesService,
		private fb: FormBuilder,
		private matDialog: MatDialog,
		public userService: UserService
	) {
	}

	get langs(): any[] {
		return this.languagesService.langs;
	}

	get currentLang(): string {
		return this.translateService.currentLang;
	}

	set currentLang(lang: string) {
		this.translateService.use(lang);
		this.translateService.currentLang = lang;
	}

	ngOnInit(): void {
		this.setDefaultLang('fr');
		this.authUser = new User(this.userService.getUser().getValue());
		this.adminCountries = this.authUser.admin_countries;
		this.selectedCountryId = this.authUser.admin_country?.id;

        if (!this.authUser.admin_country?.id && this.authUser.admin_countries.length > 0) {
            this.crudService.updateAdminCountry(this.authUser.admin_countries[0]?.id).subscribe((response) => {
                window.location.reload();
            });
        }

		this.refreshPerimeters();
	}

	setDefaultLang(lang: string): void {
		if (!this.authService.isLoggedIn().getValue()) {
			this.translateService.use(lang);
			this.translateService.currentLang = lang;
		}
	}

	onPerimeterSelect($event): void {
		localStorage.setItem('perimeter_ids', JSON.stringify($event.value));
		window.location.reload();
	}

	onCountrySelect($event): void {
		this.crudService.updateAdminCountry($event.value).subscribe((response) => {
			window.location.reload();
		});
	}

	setSelectedPerimeters(): void {
		this.selectedPerimeterIds = Object.values(JSON.parse(localStorage.getItem('perimeter_ids')));
		this.selectedPerimeterNames = this.perimeters.filter(perimeter => this.selectedPerimeterIds?.includes(perimeter.id))
			.map(perimeter => perimeter.name);
	}

	refreshPerimeters(): void {
		if (this.authService.isLoggedIn().getValue() && this.authUser.isSuperAdmin()) {
			this.crudService.getPerimeters().subscribe((response) => {
				this.perimeters = response;

				this.setSelectedPerimeters();
			});
		}
	}

	openPerimeterDialog($event, perimeter?: Perimeter): void {
		$event.stopPropagation();
		const dialogRef = this.matDialog.open(DialogPerimeterComponent, {
			data: { perimeter }
		});

		dialogRef.afterClosed().subscribe((response) => {
			if (response) {
				this.refreshPerimeters();
			}
		});
	}

	updateLang(lang: string): void {
		if (this.authService.isLoggedIn().getValue()) {
			this.crudService.updateUserLang(lang).subscribe(user => {
				this.userService.getUser().next(user);
				this.translateService.use(lang);
				this.translateService.currentLang = lang;
			});
		}
	}
}
