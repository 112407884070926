import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule, MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule, MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatChipInput, MatChipsModule } from '@angular/material/chips';
import { RouterModule } from '@angular/router';
import { CoreModule } from '@ff/core';
import { LoaderModule } from '@ff/loader';
import { ThemeModule } from '@ff/theme';
import { TranslateModule } from '@ngx-translate/core';
import { DialogPerimeterComponent } from './components/dialog-perimeter/dialog-perimeter.component';
import { DialogComponent } from './components/dialog/dialog.component';
import { UserListComponent } from './components/user-list/user-list.component';
import { DialogAssistanceComponent } from './components/dialog-assistance/dialog-assistance.component';
import { DialogFiltersComponent } from './components/dialog-filters/dialog-filters.component';
import { DialogAddUserComponent } from './components/dialog-add-user/dialog-add-user.component';
import { DialogAddExtraInfoUserComponent } from './components/dialog-add-extra-info-user/dialog-add-extra-info-user.component';


@NgModule({
	entryComponents: [
	],
	declarations: [
		UserListComponent,
		DialogComponent,
		DialogPerimeterComponent,
		DialogAssistanceComponent,
		DialogFiltersComponent,
    DialogAddExtraInfoUserComponent,
  DialogAddUserComponent],
	providers: [
		{ provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: 5000 } },
		{ provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'fill', floatLabel: 'always' } },
		DatePipe
	],
	imports: [
		// Angular
		RouterModule,
		FormsModule,
		ReactiveFormsModule,
		CommonModule,

		// FF Packages

		LoaderModule,
		CoreModule,
		ThemeModule,

		// Angular Material

		MatAutocompleteModule,
		MatButtonModule,
		MatButtonToggleModule,
		MatCardModule,
		MatCheckboxModule,
		MatDatepickerModule,
		MatDialogModule,
		MatExpansionModule,
		MatFormFieldModule,
		MatIconModule,
		MatInputModule,
		MatListModule,
		MatMenuModule,
		MatPaginatorModule,
		MatProgressBarModule,
		MatProgressSpinnerModule,
		MatRadioModule,
		MatRippleModule,
		MatSelectModule,
		MatSidenavModule,
		MatSlideToggleModule,
		MatSliderModule,
		MatSnackBarModule,
		MatSortModule,
		MatTableModule,
		MatTabsModule,
		MatToolbarModule,
		MatTooltipModule,
		MatChipsModule,

		// Others
		TranslateModule
	],
	exports: [
		// App specific

		// Components
		FormsModule,
		CommonModule,
		ReactiveFormsModule,

		// FF Packages

		LoaderModule,
		CoreModule,
		ThemeModule,

		// Angular Material

		MatAutocompleteModule,
		MatButtonModule,
		MatButtonToggleModule,
		MatCardModule,
		MatCheckboxModule,
		MatDatepickerModule,
		MatDialogModule,
		MatExpansionModule,
		MatFormFieldModule,
		MatIconModule,
		MatInputModule,
		MatListModule,
		MatMenuModule,
		MatPaginatorModule,
		MatProgressBarModule,
		MatProgressSpinnerModule,
		MatRadioModule,
		MatRippleModule,
		MatSelectModule,
		MatSidenavModule,
		MatSlideToggleModule,
		MatSliderModule,
		MatSnackBarModule,
		MatSortModule,
		MatTableModule,
		MatTabsModule,
		MatToolbarModule,
		MatTooltipModule,
		MatChipsModule,

		// Others
		TranslateModule,
		UserListComponent
	]
})
export class SharedModule {

	public constructor() {

	}

}
