import { HttpClient } from '@angular/common/http';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import {MatIconRegistry} from '@angular/material/icon';
import { BrowserModule, DomSanitizer } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { API_CONFIG } from '@ff/api';
import { ATInternetModule, AT_INTERNET_CONFIG } from '@ff/at-internet';
import { AuthModule, AUTH_CONFIG } from '@ff/auth';
import { OAuth2Module, OAUTH2_CONFIG } from '@ff/oauth2';
import { USER_CONFIG } from '@ff/user';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { environment } from 'src/environments/environment';
import { SharedModule } from '../shared/shared.module';
import { LanguagesService } from './../../services/shared/languages.service';
import { AppComponent } from './components/app/app.component';
import { HeaderComponent } from './components/header/header.component';
import { NavigationComponent } from './components/navigation/navigation.component';
import { routes } from './core.routes';
import { ServiceWorkerModule } from '@angular/service-worker';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, '/assets/i18n/', '.json');
}

@NgModule({
	declarations: [
		AppComponent,
		HeaderComponent,
		NavigationComponent
	],
	providers: [
	{
		provide: AT_INTERNET_CONFIG,
		useValue: {
			site: 616694,
			autosend: true,
			secure: true,
			collectDomain: 'logc409.xiti.com',
			collectDomainSSL: 'logs1409.xiti.com',
		}
	},
	{
		provide: API_CONFIG,
		useValue: {
		host: environment.api.host
		}
	},
	{
		provide: USER_CONFIG,
		useValue: {
		host: environment.api.host
		}
	},
	{
		provide: AUTH_CONFIG,
		useValue: {
		host: environment.api.host
		}
	},
	{
		provide: OAUTH2_CONFIG,
		useValue: {
			host: environment.api.host,
			endpoints: {
				bearer: '/oauth2/token',
				providers: '/oauth2/providers'
			}
		}
	},
	LanguagesService
  ],
  imports: [
	// Angular
	BrowserModule,
	BrowserAnimationsModule,

	// FF packages
	AuthModule,
	ATInternetModule,
	OAuth2Module,

	// Others
	RouterModule.forRoot(routes,
		// { enableTracing: true } // <-- debugging purposes only
	),
	TranslateModule.forRoot({
		loader: {
			provide: TranslateLoader,
			useFactory: HttpLoaderFactory,
			deps: [HttpClient]
		}
	}),
	SharedModule,
 ServiceWorkerModule.register('ngsw-worker.js', {
   enabled: environment.production,
   // Register the ServiceWorker as soon as the app is stable
   // or after 30 seconds (whichever comes first).
   registrationStrategy: 'registerWhenStable:30000'
 }),

	// App packages
	],
	bootstrap: [
		AppComponent
	]
})

export class CoreModule {
	public constructor(
		@Optional() @SkipSelf() parent: CoreModule,
		iconRegistry: MatIconRegistry,
		sanitizer: DomSanitizer) {
			if (parent) {
				throw new Error('CoreModule should not be exported then imported by another module!');
			}

			const iconsFolder = 'assets/pictos/';
			const svgSuffix = '.svg';
			const svgFiles = [
				'avertissement', 'avion_gris', 'avion_gris_fonce', 'avion_rouge', 'bulle',
				'cloche', 'couple_gris', 'couple_gris_fonce', 'couple_rouge', 'crayon_gris', 'crayon_gris_fonce', 'crayon_rouge',
				'download_gris', 'download_gris_fonce', 'download_rouge',
				'enfant_gris', 'enfant_gris_fonce', 'enfant_rouge', 'enregistrer', 'hotel_gris', 'hotel_gris_fonce', 'hotel_rouge',
				'logement_total_gris', 'logement_total_gris_fonce', 'logement_total_rouge',
				'maison_gris', 'maison_gris_fonce', 'maison_rouge', 'off_shore_gris', 'off_shore_gris_fonce', 'off_shore_rouge',
				'pays', 'valise_gris', 'valise_gris_fonce', 'valise_rouge', 'pdf', 'dashboard', 'personnel', 'super_admin', 'admin',
				'famille', 'autre_logements_rouge', 'autre_logements_gris', 'autre_logements_gris_fonce', 'a_la_ligne',
				'assistance_filiale_blanc', 'home_valise_blanc', 'avion_blanc', 'assistance_groupe_blanc', 'assistance_gris', 'assistance_rouge',
				'renvoie_notif_rouge', 'renvoie_notif_gris'
			];

			svgFiles.forEach(svgFile => {
				iconRegistry.addSvgIcon(svgFile, sanitizer.bypassSecurityTrustResourceUrl(iconsFolder + svgFile + svgSuffix));
			});
		}
}

