import { Component, Inject, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {LoaderService} from '@ff/loader';
import {TranslateService} from '@ngx-translate/core';
import {AdministratorService} from 'src/app/services/administrator.service';

@Component({
  selector: 'app-dialog-filters',
  templateUrl: './dialog-filters.component.html',
  styleUrls: ['./dialog-filters.component.scss']
})
export class DialogFiltersComponent implements OnInit {
    form: FormGroup;
    
    constructor(
        private fb: FormBuilder,
        private loaderService: LoaderService,
        private snackBar: MatSnackBar,
        private administratorService: AdministratorService,
        private translateService: TranslateService,
        public dialogRef: MatDialogRef<DialogFiltersComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) { 
        
    }

    ngOnInit(): void {
        this.initForm();
    }
    
    initForm():void {
        this.form = this.fb.group({
            name: ['', Validators.required],
            filters: [this.data.filters]
        });
    }
    
    submit(): void {
		if (this.form.valid) {
			this.loaderService.show();
			this.administratorService.saveSearchFilters(this.form.getRawValue()).subscribe(response => {
				this.loaderService.hide();
				this.snackBar.open(this.translateService.instant('general.messages.info_saved'));
				this.dialogRef.close(true);
			}, error => {
				this.snackBar.open(error);
				this.loaderService.hide();
			});
		}
	}

	close(): void {
		this.dialogRef.close();
	}
}