<mat-card class="mb-3">
    <div class="d-flex justify-content-end align-items-center">
        <button mat-raised-button color="primary" (click)="openAddUserDialog()">
            <mat-icon class="mr-2">add_circle</mat-icon>
            {{ 'user_search.labels.add_user' | translate }}
        </button>
    </div>
</mat-card>
<!-- FILTERS -->
<mat-card class="mb-5">
    <form [formGroup]="form">
        <div class="d-flex justify-content-between">
            <span class="filter-text">{{ 'user_search.user_filters.filter_list' | translate }} :</span>
            <div>
                <ng-container *ngIf="authUser.saved_search_filters?.length > 0">
                    <mat-form-field appearance="fill" class="mr-3">
                        <mat-select (selectionChange)="loadSavedSearchFilters($event)">
                            <mat-select-trigger>
                                {{ selectedSavedSearch }}
                            </mat-select-trigger>
                            <mat-option value=""></mat-option>
                            <mat-option *ngFor="let saved_search of authUser.saved_search_filters; let i = index" [value]="saved_search">
                                <div class="d-flex justify-content-between">
                                    {{ saved_search.name }}
                                    <button mat-icon-button class="mt-1" (click)="deleteSavedSearch(saved_search.id)">
                                        <mat-icon class="m-0 color-red-600">delete</mat-icon>
                                    </button>
                                </div>
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    |
                </ng-container>
                <button mat-flat-button color="primary" class="ml-3" (click)="openSaveFiltersDialog()">
                    <mat-icon>save</mat-icon>
                    {{ 'user_search.labels.save_filters' | translate }}
                </button>
            </div>
        </div>
        <br/><br/>
        <span class="select-filter-text">{{ 'user_search.user_filters.employee' | translate }}</span><br/>

        <mat-form-field class="w-100">
            <mat-chip-list #chipList aria-label="User selection">
                <mat-chip *ngFor="let user of selectedUsers" [selectable]="true" [removable]="true" (removed)="removeSelectedUser(user)" (matChipInputTokenEnd)="addUser($event)">
                    <span>{{ (user.name) ? user.name + ' (' + user.igg + ')' : '' }}</span>
                    <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>
                <input type="text" matInput #userInput [matAutocomplete]="auto" [matChipInputFor]="chipList" formControlName="userFilter">
            </mat-chip-list>
            <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" [displayWith]="userAutocompleteDisplayFn" (optionSelected)="selectUser($event)">
                <mat-option *ngFor="let user of filteredResults | async" [value]="user">
                    <span>{{ (user.name) ? user.name + ' (' + user.igg + ')' : '' }}</span>
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>

        <div class="user-checkboxes mt-2 mb-3">
            <mat-checkbox formControlName="withSpouse" color="primary" class="col-md-3">{{ 'user_search.user_filters.spouse' | translate }}</mat-checkbox>
            <mat-checkbox formControlName="withChild" color="primary" class="col-md-3">{{ 'user_search.user_filters.child' | translate }}</mat-checkbox>
            <mat-checkbox formControlName="withPrivateVisitor" color="primary" class="col-md-3">{{ 'user_search.user_filters.personal_guest' | translate }}</mat-checkbox>
            <mat-checkbox formControlName="withProfessionalVisitor" color="primary" class="col-md-3">{{ 'user_search.user_filters.professional_guest' | translate }}</mat-checkbox>
            <br/>
            <mat-checkbox formControlName="withInternalStaff" color="primary" class="col-md-3">{{ 'user_search.user_filters.personnal_igg_j' | translate }}</mat-checkbox>
            <mat-checkbox formControlName="withExternalStaff" color="primary" class="col-md-3">{{ 'user_search.user_filters.personnal_igg_l' | translate }}</mat-checkbox>
            <mat-checkbox formControlName="withInternalTrainee" color="primary" class="col-md-3">{{ 'user_search.user_filters.trainee_igg_j' | translate }}</mat-checkbox>
            <mat-checkbox formControlName="withExternalTrainee" color="primary" class="col-md-3">{{ 'user_search.user_filters.trainee_igg_l' | translate }}</mat-checkbox>
        </div>
        <div #user_filters class="d-flex flex-wrap">
            <div class="mr-3">
                <span class="select-filter-text">{{ 'user_search.user_filters.branch' | translate }}</span><br/>
                <mat-form-field appearance="fill">
                    <mat-select multiple formControlName="branches">
                        <mat-option *ngFor="let branch of filters.branches" [value]="branch">{{ branch }}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="mr-3">
                <span class="select-filter-text">{{ 'user_search.user_filters.entity' | translate }}</span><br/>
                <mat-form-field appearance="fill">
                    <mat-select multiple formControlName="entities">
                        <mat-option *ngFor="let entity of filters.entities" [value]="entity">{{ entity }}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="mr-3">
                <span class="select-filter-text">{{ 'user_search.user_filters.nationality' | translate }}</span><br/>
                <mat-form-field appearance="fill">
                    <mat-select multiple formControlName="nationalities" (selectionChange)="updateSelectedOptions($event)">
                        <mat-option value="show_schengen" class="checkbox_hidden">{{ 'user_search.user_filters.schengen_list' | translate }}</mat-option>
                        <mat-option value="show_residence_country" class="checkbox_hidden">{{ 'user_search.user_filters.residence_country' | translate }}</mat-option>
                        <mat-option value="show_other_country" class="checkbox_hidden">{{ 'user_search.user_filters.residence_other_country' | translate }}</mat-option>
                        <mat-option value="reset" class="checkbox_hidden">{{ 'user_search.user_filters.reset_selection' | translate }}</mat-option>
                        <hr/>
                        <mat-option *ngFor="let nationality of filters.nationalities" [value]="nationality?.id">{{ nationality['label_' + authUser.lang_code] }}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="mr-3">
                <span class="select-filter-text">{{ 'user_search.user_filters.housing_type' | translate }}</span><br/>
                <mat-form-field appearance="fill">
                    <mat-select multiple formControlName="residence_types">
                        <mat-option *ngFor="let residenceType of filters.residenceTypes" [value]="residenceType?.id">{{ ('residences_types.' + residenceType.label_key) | translate }}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="mr-3">
                <span class="select-filter-text">{{ 'user_search.user_filters.housing' | translate }}</span><br/>
                <mat-form-field appearance="fill">
                    <mat-select multiple formControlName="residences">
                        <mat-option *ngFor="let residence of filters.residences" [value]="residence?.id">{{ residence.name | translate }}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="mr-3">
                <span class="select-filter-text">{{ 'user_search.user_filters.status' | translate }}</span><br/>
                <mat-form-field appearance="fill">
                    <mat-select multiple formControlName="statuses">
                        <mat-option *ngFor="let status of filters.statuses" [value]="status?.id">{{ ('statuses.' + status.label_key) | translate }}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="mr-3">
                <span class="select-filter-text">{{ 'user_search.user_filters.on_board' | translate }}</span><br/>
                <mat-form-field appearance="fill">
                    <mat-select multiple formControlName="is_inside_country">
                        <mat-option [value]="1">{{ 'general.yes' | translate }}</mat-option>
                        <mat-option [value]="0">{{ 'general.no' | translate }}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="mr-3">
                <span class="select-filter-text">{{ 'user_search.user_filters.assistance' | translate }}</span><br/>
                <mat-form-field appearance="fill">
                    <mat-select multiple formControlName="assistances">
                        <mat-option [value]="1">{{ 'dashboard.group_assistance' | translate }}</mat-option>
                        <mat-option [value]="2">{{ 'dashboard.group_affiliate' | translate }}</mat-option>
                        <mat-option [value]="3">{{ 'dashboard.group_provider' | translate }}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="d-flex justify-content-end">
            <button mat-flat-button color="primary" class="ml-3" (click)="resetFilters()">
                <mat-icon>search_off</mat-icon>
                {{ 'general.reset' | translate }}
            </button>
            <button mat-flat-button color="primary" class="ml-3" (click)="handleFilterEvent()">
                <mat-icon>search</mat-icon>
                {{ 'general.filter' | translate }}
            </button>
        </div>
    </form>
    <hr />
    <div class="text-right" *ngIf="enableDownload">
        <span class="staff-text mr-3"><span class="color-red-600">{{ employeesCount }}</span>&nbsp;{{ 'dashboard.staff' | translate }}</span> |
        <button mat-flat-button color="primary" class="ml-3" (click)="exportEmployees()">
            <mat-icon>file_download</mat-icon>
            {{ 'general.download' | translate }}
        </button>
    </div>
</mat-card>

<!-- USERS TABLE -->
<table mat-table matSort [dataSource]="dataSource" multiTemplateDataRows class="w-100">
    <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let user" class="user-name">{{ getInfo(user.name) }}<br>
            <span class="user-igg">{{ user.igg }}</span> <span *ngIf="user.igg != '' "> · </span>
            <span class="user-branch">{{ user.branch }}</span>
        </td>
    </ng-container>
    <ng-container matColumnDef="nationality">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'user_search.user_results.header.nationality' | translate }}</th>
        <td mat-cell *matCellDef="let user" class="text-center">
            <img *ngIf="user.nationality != null" class="mr-2" [src]="'assets/images/flags/' + user.nationality.code + '.png'" [matTooltip]="user.nationality.label" height="18">
            <img *ngIf="user.second_nationality != null" class="mr-2" [src]="'assets/images/flags/' + user.second_nationality.code + '.png'" [matTooltip]="user.second_nationality.label" height="18">
        </td>
    </ng-container>
    <ng-container matColumnDef="residence">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'user_search.user_results.header.residence' | translate }}</th>
        <td mat-cell *matCellDef="let user">
            <div class="d-flex">
                <ng-container *ngIf="user.is_inside_country" [ngSwitch]="user.residence?.residence_type_id" class="col-4">
                    <ng-container>
                        <mat-icon *ngSwitchCase="1" class="mr-2" [svgIcon]="'logement_total_rouge'"></mat-icon>
                        <mat-icon *ngSwitchCase="2" class="mr-2" [svgIcon]="'hotel_rouge'"></mat-icon>
                        <mat-icon *ngSwitchCase="3" class="mr-2" [svgIcon]="'off_shore_rouge'"></mat-icon>
                    </ng-container>
                </ng-container>
                <ng-container *ngIf="!user.is_inside_country" class="col-4">
                    <mat-icon class="mr-2" [matTooltip]="getInfo(user.residence?.name | translate)" [svgIcon]="'avion_gris'"></mat-icon>
                </ng-container>
                <div class="col-8 p-0">{{ user.is_inside_country ? getInfo(user.residence?.name | translate) : ('user_search.user_results.labels.travelling' | translate) }}</div>
            </div>
        </td>
    </ng-container>
    <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'user_search.user_results.header.email' | translate }}</th>
        <td mat-cell *matCellDef="let user">{{ getInfo(user.email) }}</td>
    </ng-container>
    <ng-container matColumnDef="phone">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'user_search.user_results.header.phone' | translate }}</th>
        <td mat-cell *matCellDef="let user">{{ getInfo(user.phone) }}</td>
    </ng-container>
    <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'user_search.user_results.header.status' | translate }}</th>
        <td mat-cell *matCellDef="let user">{{ user.status != null ? ('statuses.' + user.status.label_key | translate) : '- -' }}</td>
    </ng-container>
    <ng-container matColumnDef="last_connection">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'user_search.user_results.header.last_connection' | translate }}</th>
        <td mat-cell *matCellDef="let user">{{ user.last_connection != null ? (user.last_connection | date:"dd/MM/Y") : '- -' }}</td>
    </ng-container>
    <ng-container matColumnDef="is_inside_country">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'user_search.user_results.header.is_inside_country' | translate }}</th>
        <td mat-cell *matCellDef="let user">
            <div class="d-flex align-items-center">
                <div>
                    <mat-slide-toggle color="primary" [checked]="user.is_inside_country == 1" (click)="toggleIsInsideCountry($event, user)">
                        {{ (user.is_inside_country ? 'general.yes' : 'general.no') | translate }}
                    </mat-slide-toggle>
                </div>
            </div>
        </td>
    </ng-container>
    <ng-container matColumnDef="relationship">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let user">
            <div class="d-flex align-items-center">
                <div class="user-relation-number">{{ user.user_relations?.length }}</div>
                <div>
                    <mat-icon class="ml-1 red" [svgIcon]="'famille'"></mat-icon>
                </div>
            </div>
        </td>
    </ng-container>
    <ng-container matColumnDef="other">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let user">
            <div class="d-flex align-items-center">
                <div class="pt-2">
                    <button mat-icon-button color="primary" class="icon-button" *ngIf="!user.is_assistance_locked" (click)="openAssistanceDialog(user)">
                        <mat-icon [svgIcon]="'assistance_gris'"></mat-icon>
                    </button>
                    <button mat-icon-button class="icon-button" *ngIf="user.is_assistance_locked" (click)="openUnlockDialog(user)">
                        <mat-icon [svgIcon]="'assistance_rouge'"></mat-icon>
                    </button>
                    <button mat-icon-button class="icon-button" (click)="removeEmployee(user.user_id)">
                        <mat-icon>delete</mat-icon>
                    </button>
                    <button mat-icon-button class="icon-button" (click)="openLoginWithOtherAccountDialog(user.user_id)">
                        <mat-icon>account_circle</mat-icon>
                    </button>
                    <button mat-icon-button class="icon-button" (click)="completePersonnelPrivateInformation(user.id)">
                      <mat-icon>description</mat-icon>
                  </button>
                </div>
                <ng-container *ngIf="user.user_relations?.length > 0">
                    <div class="vertical-divider mr-2"></div>
                    <div class="detail-link">
                        <a (click)="updateExpandedRows(user)">{{ 'user_search.user_results.labels.details' | translate }}</a>
                    </div>
                    <div>
                        <mat-icon>{{ expandedRows.includes(user) ? 'expand_less' : 'expand_more' }}</mat-icon>
                    </div>
                </ng-container>
            </div>
        </td>
    </ng-container>

    <!-- USER RELATIONSHIPS -->
    <ng-container matColumnDef="expandedDetail">
        <td mat-cell *matCellDef="let detail" [attr.colspan]="displayedColumns.length" [ngClass]="{'user-detail-separator': expandedRows.includes(detail)}">
            <div class="user-detail" [@detailExpand]="expandedRows.includes(detail) ? 'expanded' : 'collapsed'">
                <div *ngFor="let user of detail.user_relations; let i = index" class="row no-gutters w-100 user-relationship" [ngClass]="{'last': i >= detail.user_relations?.length - 1 }">
                    <div class="user-name col-md-2 d-flex">
                        <ng-container [ngSwitch]="user.relation_type_id" class="col-4">
                            <ng-container>
                                <mat-icon *ngSwitchCase="1" class="mr-2" [svgIcon]="user.is_inside_country ? 'couple_rouge' : 'couple_gris' "></mat-icon>
                                <mat-icon *ngSwitchCase="2" class="mr-2" [svgIcon]="user.is_inside_country ? 'enfant_rouge' : 'enfant_gris' "></mat-icon>
                                <mat-icon *ngSwitchDefault class="mr-2" [svgIcon]="user.is_inside_country ? 'valise_rouge' : 'valise_gris' "></mat-icon>
                            </ng-container>
                        </ng-container>
                        <div class="col-8 p-0">{{ user.name }}<br/><span class="user-category">{{ "user_search.user_results.labels." + user.category | translate }}</span></div>
                    </div>
                    <div class="col-md-1">
                        <img *ngIf="user.nationality != null" class="mr-2" [src]="'assets/images/flags/' + user.nationality.code + '.png'" [matTooltip]="user.nationality.label" height="18">
                        <img *ngIf="user.second_nationality != null" class="mr-2" [src]="'assets/images/flags/' + user.second_nationality.code + '.png'" [matTooltip]="user.second_nationality.label" height="18">
                    </div>
                    <div class="col-md-2 d-flex">
                        <ng-container *ngIf="user.is_inside_country" [ngSwitch]="user.residence?.residence_type_id" class="col-4">
                            <ng-container>
                                <mat-icon *ngSwitchCase="1" class="mr-2" [svgIcon]="'logement_total_rouge'"></mat-icon>
                                <mat-icon *ngSwitchCase="2" class="mr-2" [svgIcon]="'hotel_rouge'"></mat-icon>
                                <mat-icon *ngSwitchCase="3" class="mr-2" [svgIcon]="'off_shore_rouge'"></mat-icon>
                                <mat-icon *ngSwitchCase="4" class="mr-2" [svgIcon]="'autre_logements_rouge'"></mat-icon>
                            </ng-container>
                        </ng-container>
                        <ng-container *ngIf="!user.is_inside_country" class="col-4">
                            <mat-icon class="mr-2" [matTooltip]="getInfo(user.residence?.name | translate)" [svgIcon]="'avion_gris'"></mat-icon>
                        </ng-container>
                        <div class="col-8 p-0">{{ user.is_inside_country ? getInfo(user.residence?.name | translate) : ('user_search.user_results.labels.travelling' | translate) }}</div>
                    </div>
                    <div class="col-md-2">{{ getInfo(user.email) }}</div>
                    <div class="col-md-1">{{ getInfo(user.phone) }}</div>
                    <div class="col-md-1 mr-2">{{ user.status != null ? ('statuses.' + user.status.label_key | translate) : '- -' }}</div>
                    <div class="col-md-1">{{ user.last_connection != null ? (user.last_connection | date:"dd/MM/Y") : '- -' }}</div>
                    <div>
                        <mat-slide-toggle color="primary" [checked]="user.is_inside_country == 1" (click)="toggleIsInsideCountry($event, user)">
                            {{ (user.is_inside_country ? 'general.yes' : 'general.no') | translate }}
                        </mat-slide-toggle>
                    </div>
                </div>
            </div>
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns; let i = index"></tr>
    <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="detail-row"></tr>
</table>
<mat-paginator [hidePageSize]="true" [pageSizeOptions]="[10]" showFirstLastButtons></mat-paginator>
<div *ngIf="employeesCount <= 0" class="text-center">
    {{ 'general.messages.no_data_found' | translate }}
</div>
