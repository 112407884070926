import {HttpClient} from '@angular/common/http';
import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {endpoints} from 'src/environments/endpoints';
import {environment} from 'src/environments/environment';
import {Country} from '../models/country';

@Injectable({
  providedIn: 'root'
})
export class CountryService {
	constructor(private http: HttpClient) {}

	getAllByGeographicArea(geographicAreaId: number): Observable<Country[]> {
		return this.http.get(environment.api.host + endpoints.adminCountryByGeographicArea + '/' + geographicAreaId + '/all').pipe(
			map((res: any) => res.data.countries.map(country => new Country(country))),
			catchError(this.handleError)
		);
	}

	getByGeographicArea(geographicAreaId: number): Observable<Country[]> {
		return this.http.get(environment.api.host + endpoints.adminCountryByGeographicArea + '/' + geographicAreaId).pipe(
			map((res: any) => res.data.countries.map(country => new Country(country))),
			catchError(this.handleError)
		);
	}

	getByPerimeters(perimeter_ids: number[]): Observable<Country[]> {
		return this.http.post(environment.api.host + endpoints.adminCountryByPerimeter, { perimeter_ids }).pipe(
			map((res: any) => res.data.countries.map(country => new Country(country))),
			catchError(this.handleError)
		);
	}

	updateState(countryId: number, is_active: boolean): Observable<Country> {
		return this.http.put(environment.api.host + endpoints.adminCountries + '/' + countryId, { is_active }).pipe(
			catchError(this.handleError)
		);
	}

	updateHasResidenceState(countryId: number, state: boolean): Observable<Country> {
		return this.http.put(environment.api.host + endpoints.adminCountries + '/' + countryId + '/otherResidenceState', { state }).pipe(
			catchError(this.handleError)
		);
	}

	updateHasShipState(countryId: number, state: boolean): Observable<Country> {
		return this.http.put(environment.api.host + endpoints.adminCountries + '/' + countryId + '/otherShipState', { state }).pipe(
			catchError(this.handleError)
		);
	}

	protected handleError(error: any): Promise<any> {
		if (error.status === 401) {
			window.location.reload();
		}

		return Promise.reject(error?.error?.message || error?.message || error);
	}
}
