<header>
    <div class="header-container">
        <a [routerLink]="'/dashboard'" class="home-link text-decoration-none">
            <img src="assets/TotalEnergies-logo.svg" title="TotalEnergies" alt="TotalEnergies" class="logo" />
            <div class="app-name">{{'general.app_name' | translate}}</div>
        </a>
        <div class="d-flex align-items-center" *ngIf="userService.getUser() | async">
            <ng-container *ngIf="authUser.isAdmin()">
                <span class="user-info mr-2">{{ 'header.country' | translate }} :</span>
                <mat-select placeholder="{{ 'countries.country' | translate }}" class="countries-select mr-3" [(ngModel)]="selectedCountryId" (selectionChange)="onCountrySelect($event)">
                    <mat-option *ngFor="let country of adminCountries" [value]="country.id">
                        <img [src]="'assets/images/flags/' + country?.code + '.png'" [alt]="country?.label" [title]="country?.label" height="16" class="m-3" />
                        <span>{{ country?.label }}</span>
                    </mat-option>
                </mat-select>
            </ng-container>
            <ng-container *ngIf="authUser.isSuperAdmin()">
                <button *ngIf="perimeters.length == 0" class="add-perimeter-button mr-3 pt-1" mat-flat-button (click)="openPerimeterDialog($event)">
                    <mat-icon class="mr-1 mb-1">add</mat-icon>{{ "header.add_perimeter" | translate }}
                </button>
                <mat-form-field class="perimeter-select mr-3" *ngIf="perimeters.length > 0">
                    <mat-select placeholder="{{ 'header.perimeter_choice' | translate }}" multiple (selectionChange)="onPerimeterSelect($event)" [(ngModel)]="selectedPerimeterIds">
                        <mat-select-trigger>
                            {{ selectedPerimeterNames?.join(', ') }}
                        </mat-select-trigger>
                        <mat-option *ngFor="let perimeter of perimeters" [value]="perimeter.id">
                            <div class="d-flex justify-content-between">
                                {{ perimeter.name }}
                                <button mat-icon-button class="mt-1" (click)="openPerimeterDialog($event, perimeter)">
                                    <mat-icon class="m-0" class="color-red">edit</mat-icon>
                                </button>
                            </div>
                        </mat-option>
                        <button mat-flat-button color="primary" class="w-100 pl-4 pt-2 pb-2 text-left" (click)="openPerimeterDialog($event)">
                            <mat-icon class="mr-3">add_circle</mat-icon>
                            {{ "general.add" | translate }}
                        </button>
                    </mat-select>
                </mat-form-field>
            </ng-container>
            <div class="vertical-divider mr-3"></div>
            <mat-select [(ngModel)]="currentLang" name="currentLang" (selectionChange)="updateLang(currentLang)" class="lang-select">
                <mat-option *ngFor="let lang of langs" [value]="lang.code">
                    {{lang.code.toUpperCase()}}
                </mat-option>
            </mat-select>
            <ng-container>
                <div class="vertical-divider ml-3 mr-3"></div>
                <span class="user-info pt-1">{{ authUser.first_name }} {{ authUser.last_name }} ({{ authUser.igg }}) - <span class="color-red-600">{{ authUser.role | uppercase }}</span></span>

                <div class="vertical-divider ml-3 mr-2"></div>
                <button mat-flat-button [routerLink]="'/auth/logout'" class="pl-2 pr-1 mr-2 pt-1">
					<span class="mr-2 signout-button">{{ 'login.signout' | translate }}</span>
					<mat-icon color="primary">exit_to_app</mat-icon>
				</button>
            </ng-container>
        </div>
    </div>
</header>