import { Component } from '@angular/core';
import { ATInternetService } from '@ff/at-internet';
import { AuthService } from '@ff/auth';
import { UserService } from '@ff/user';
import { TranslateService } from '@ngx-translate/core';
import {User} from 'src/app/models/user';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
	authUser: User;

  public constructor(
	private translate: TranslateService,
	private atInternetService: ATInternetService, // PLEASE don't remove, it is needed for Xiti to work
	private authService: AuthService,
	public userService: UserService
  ) {
	if (this.authService.isLoggedIn().getValue()) {
		this.authUser = new User(this.userService.getUser().getValue());
	}
	this.initLang(translate);
	this.initLocalStorage();
  }

  onActivate(event): void {
	window.scroll(0, 0);
  }

  private initLocalStorage(): void {
	if (localStorage.getItem('perimeter_ids') === null) {
		localStorage.setItem('perimeter_ids', JSON.stringify([]));
    }
    if (localStorage.getItem('loggedin') !== 'false') {
        localStorage.setItem('loggedin', 'false');
    }
  }

  private initLang(translate: TranslateService): void {
	let currentLang = 'en';
	const translatorLang = this.translate.currentLang;

	if (translatorLang) {
		currentLang = translatorLang;
	}

	if (this.authService.isLoggedIn().getValue()) {
		const user = this.userService.getUser().getValue();
		currentLang = user?.lang_code.toLowerCase() == "en" || user?.lang_code.toLowerCase() == "fr" ? user?.lang_code : 'en';
	}

	this.translate.setDefaultLang(currentLang);
	translate.use(currentLang);
  }
}
