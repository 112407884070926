<div class="dialog-wrapper">
    <div class="d-flex justify-content-between">
        <h1>{{ "dialog-save-filters.title" | translate }}</h1>
    </div>
    <div [formGroup]="form" class="mt-4 perimeter-form">
        <h2 class="mb-4">{{ "dialog-save-filters.prompt" | translate }}:</h2>
        <mat-form-field class="w-100 mb-5">
            <mat-label class="text">{{ "dialog-save-filters.save_name" | translate }}</mat-label>
            <input type="text" matInput formControlName="name">
        </mat-form-field>

        <button mat-flat-button color="primary" (click)="submit()" class="mr-3" [disabled]="!form.valid">
            <mat-icon>arrow_right</mat-icon>
            {{ 'general.validate' | translate }}
        </button>
        <button mat-stroked-button color="primary" (click)="close()">
            <mat-icon>arrow_right</mat-icon>
            {{ 'general.cancel' | translate }}
        </button>
    </div>
</div>