import {Country} from './country';
import {Residence} from './residence';
import {ResidenceType} from './residence-type';

export class UserResult {
	id: number;
	name: string;
	igg: string;
	nationality: Country;
	relation_type_id: number;
	second_nationality: Country;
	residence: Residence;
	category: string;
	email: string;
	phone: string;
	status: number;
	residence_type: ResidenceType;
	last_connection: Date;
	is_inside_country: boolean;
	user_relations: UserResult[];
	assistance_group: number;
	is_assistance_locked: boolean;
	compositions: string;
	addresses: string;
	trips: string;
	diverses_info: string;
	another_infos: string;
  	diverses_info_client: string;

	constructor(data = {}) {
		Object.assign(this, data);
	}
}
