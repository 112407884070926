import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AuthService } from '@ff/auth';
import { UserService } from '@ff/user';
import { Observable } from 'rxjs';
import {User} from 'src/app/models/user';

@Injectable({
  providedIn: 'root'
})
export class AppAdminGuard implements CanActivate {

	constructor(
		public authService: AuthService,
		private userService: UserService,
		private router: Router) {
	}

	canActivate(
		next: ActivatedRouteSnapshot,
		state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

		try {
			const isLoggedIn = this.authService.isLoggedIn().getValue();

			if (isLoggedIn) {
				const user = new User(this.userService.getUser().getValue());

			if (user.isAdmin() || user.isSuperAdmin()) {
				return true;
			} else {
				this.gotoLogin();
			}
			} else {
				this.gotoLogin();
			}
		} catch (error) {
			this.gotoLogin();
		}
	}

	private gotoLogin(): void {
		this.router.navigate(['/login']);
	}
}
