import {Country} from './country';
import {Residence} from './residence';
import {ResidenceType} from './residence-type';
import {Status} from './status';

export class UserFilters {
	branches: any[];
    entities: any[];
	nationalities: Country[];
	residenceTypes: ResidenceType[];
	residences: Residence[];
	statuses: Status[];
	is_inside_country: any[];
    assistances: any[];
}
