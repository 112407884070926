<ul>
    <li routerLinkActive="active" [routerLink]="coreRoutesNames.DASHBOARD">
        <mat-icon [svgIcon]="'dashboard'" class="mr-2"></mat-icon>{{'navigation.dashboard' | translate}}
    </li>
    <li routerLinkActive="active" [routerLink]="coreRoutesNames.EMPLOYEES">
        <mat-icon [svgIcon]="'personnel'" class="mr-2"></mat-icon>{{'navigation.employees' | translate}}
    </li>
    <li routerLinkActive="active" [routerLink]="coreRoutesNames.RESIDENCES">
        <mat-icon [svgIcon]="'maison_rouge'" class="mr-2"></mat-icon>{{'navigation.residences' | translate}}
    </li>
    <li routerLinkActive="active" [routerLink]="coreRoutesNames.NOTIFICATIONS">
        <mat-icon [svgIcon]="'cloche'" class="mr-2"></mat-icon>{{'navigation.notifications' | translate}}
    </li>
    <li routerLinkActive="active" *ngIf="authUser.isAdmin()" [routerLink]="coreRoutesNames.HELP">
        <mat-icon [svgIcon]="'bulle'" class="mr-2"></mat-icon>{{'navigation.help' | translate}}
    </li>
    <li routerLinkActive="active" *ngIf="authUser.isSuperAdmin()" [routerLink]="coreRoutesNames.ADMINISTRATORS">
        <mat-icon [svgIcon]="'admin'" class="mr-2"></mat-icon>{{'navigation.administrators' | translate}}
    </li>
    <li routerLinkActive="active" *ngIf="authUser.isSuperAdmin()" [routerLink]="coreRoutesNames.COUNTRIES">
        <mat-icon [svgIcon]="'pays'" class="mr-2"></mat-icon>{{'navigation.countries' | translate}}
    </li>
    <li routerLinkActive="active" *ngIf="authUser.isSuperAdmin()" [routerLink]="coreRoutesNames.DOCUMENTS">
        <mat-icon [svgIcon]="'pdf'" class="mr-2"></mat-icon>{{'navigation.documents' | translate}}
    </li>
</ul>