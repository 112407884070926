import { Injectable } from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {DialogComponent} from 'src/app/modules/shared/components/dialog/dialog.component';

@Injectable({
  providedIn: 'root'
})
export class DialogService {
	constructor(
		public dialog: MatDialog,
	) { }

	showPrompt(text: string, value: string): MatDialogRef<DialogComponent, any> {
		const dialogRef = this.dialog.open(DialogComponent, {
			data: { text, value, type: 'prompt' }
		});

		return dialogRef;
	}

	showConfirm(text: string): MatDialogRef<DialogComponent, any> {
		const dialogRef = this.dialog.open(DialogComponent, {
			data: { text, value: true, type: 'confirm' }
		});

		return dialogRef;
	}

	showAlert(text: string): MatDialogRef<DialogComponent, any> {
		const dialogRef = this.dialog.open(DialogComponent, {
			data: { text, type: 'alert' }
		});

		return dialogRef;
	}

	showErrorMsg(text: string, error: any): MatDialogRef<DialogComponent, any> {
		const dialogRef = this.dialog.open(DialogComponent, {
			data: { title: 'Application Error', text, type: 'alert' }
		});

		return dialogRef;
	}
}
