<div class="dialog-wrapper">
    <div class="d-flex justify-content-between">
        <h1>{{ "dialog-assistance.title" | translate }}</h1>
    </div>
    <div [formGroup]="form" class="mt-4 perimeter-form">
        <h2 class="mb-4">{{ "dialog-assistance.assistance_choice" | translate }}:</h2>
        <div class="row">
            <mat-radio-group formControlName="assistance_group">
                <mat-radio-button class="ml-4 mb-3" color="primary" [value]="2">
                    {{'dashboard.group_affiliate' | translate}}
                </mat-radio-button>
                <br/>
                <mat-radio-button class="ml-4 mb-3" color="primary" [value]="1">
                    {{'dashboard.group_assistance' | translate}}
                </mat-radio-button>
                <br/>
                <mat-radio-button class="ml-4 mb-3" color="primary" [value]="3">
                    {{'dashboard.group_provider' | translate}}
                </mat-radio-button>
            </mat-radio-group>
        </div>
        <br/>

        <button mat-flat-button color="primary" (click)="submit()" class="mr-3">
            <mat-icon>arrow_right</mat-icon>
            {{ 'general.validate' | translate }}
        </button>
        <button mat-stroked-button color="primary" (click)="close()">
            <mat-icon>arrow_right</mat-icon>
            {{ 'general.cancel' | translate }}
        </button>
    </div>
</div>