import { Component, Inject, OnInit } from '@angular/core';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {LoaderService} from '@ff/loader';
import {UserService} from '@ff/user';
import {TranslateService} from '@ngx-translate/core';
import {Country} from 'src/app/models/country';
import {GeographicArea} from 'src/app/models/geographic-area';
import {Perimeter} from 'src/app/models/perimeter';
import {User} from 'src/app/models/user';
import {CountryService} from 'src/app/services/country.service';
import {CrudService} from 'src/app/services/shared/crud.service';
import {DialogService} from 'src/app/services/shared/dialog.service';

@Component({
  selector: 'app-dialog-perimeter',
  templateUrl: './dialog-perimeter.component.html',
  styleUrls: ['./dialog-perimeter.component.scss']
})
export class DialogPerimeterComponent implements OnInit {
	form: FormGroup;
	geographicAreas: GeographicArea[] = [];
	countries: Country[] = [];
	authUser: User;
	perimeter: Perimeter;

	constructor(
		private fb: FormBuilder,
		private snackBar: MatSnackBar,
		private countryService: CountryService,
		private crudService: CrudService,
		private userService: UserService,
		private loaderService: LoaderService,
		private translateService: TranslateService,
		private snackbar: MatSnackBar,
		private dialog: DialogService,
		public dialogRef: MatDialogRef<DialogPerimeterComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any
	) { }

	get form_country_checkboxes(): FormArray {
		return (this.form.controls.country_checkboxes as FormArray);
	}

	ngOnInit(): void {
		this.authUser = new User(this.userService.getUser().getValue());
		this.perimeter = this.data.perimeter;

		this.initForm();
		this.initGeographicAreas();
		this.initEvents();
	}

	initForm(): void {
		this.form = this.fb.group({
			name: [this.perimeter?.name, [Validators.required, Validators.maxLength(255)]],
			geographic_area_id: ['', [Validators.required]],
			country_ids: [[], [Validators.required]],
			country_checkboxes: this.fb.array([]),
		});
	}

	initEvents(): void {
		this.form.controls.geographic_area_id.valueChanges.subscribe((geographic_area_id: number) => {
			this.refreshCountries(geographic_area_id);
		});
	}

	initGeographicAreas(): void {
		this.loaderService.show();
		this.crudService.getGeographicAreas().subscribe(response => {
			this.loaderService.hide();
			this.geographicAreas = response;
			if (this.perimeter) {
				this.form.get('geographic_area_id').setValue(this.perimeter?.geographic_area_id);
			}
		});
	}

	refreshCountries(geographic_area_id: number): void {
		this.loaderService.show();
		this.countryService.getByGeographicArea(geographic_area_id).subscribe(response => {
			this.loaderService.hide();
			this.countries = response;

			this.form_country_checkboxes.clear();
			this.countries.map((obj, index) => {
				const control = new FormControl(this.perimeter ? this.perimeter.countries.find(country => country.id === obj.id) : false);
				this.form_country_checkboxes.push(control);
			});
		});
	}

	getSelectedCountriesIds(): Array<number> {
		return this.form.value.country_checkboxes
			.map((value, index) => value ? this.countries[index].id : null)
			.filter(value => value !== null);
	}

	removePerimeter(): void {
		if (this.perimeter) {
			const dialogRef = this.dialog.showConfirm(
				this.translateService.instant('general.messages.confirm_delete')
			);

			dialogRef.afterClosed().subscribe((confirm: boolean) => {
				if (confirm === true) {
					this.loaderService.show();
					this.crudService.deletePerimeter(this.perimeter.id).subscribe(response => {
						this.loaderService.hide();
						this.snackbar.open(this.translateService.instant('general.messages.item_deleted'));
						this.removeStoredPerimeter();
						window.location.reload();
					}, error => {
						this.snackbar.open(error);
						this.loaderService.hide();
					});
				}
			});
		}
	}

	removeStoredPerimeter(): void {
		const perimeter_ids = Object.values(JSON.parse(localStorage.getItem('perimeter_ids')));

		perimeter_ids.splice(perimeter_ids.indexOf(this.perimeter.id), 1);
		localStorage.setItem('perimeter_ids', JSON.stringify(perimeter_ids));
	}

	submit(): void {
		this.form.controls.country_ids.setValue(this.getSelectedCountriesIds());

		if (this.form.valid) {
			this.loaderService.show();
			this.crudService.putPerimeter(this.form.getRawValue(), this.perimeter?.id).subscribe(perimeter => {
				this.loaderService.hide();
				this.snackBar.open(this.translateService.instant('general.messages.info_saved'));
				this.dialogRef.close(true);
			}, error => {
				this.snackBar.open(error);
				this.loaderService.hide();
			});
		}
	}

	close(): void {
		this.dialogRef.close();
	}
}
