import { Component, OnInit } from '@angular/core';
import { UserService } from '@ff/user';
import {User} from 'src/app/models/user';
import { coreRoutesNames } from '../../core.routes.names';


@Component({
	selector: 'app-navigation',
	templateUrl: './navigation.component.html',
	styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit {
	coreRoutesNames: any;
	authUser: User;

	constructor(
		private userService: UserService,
	) { }

	ngOnInit(): void {
		this.authUser = new User(this.userService.getUser().getValue());
		this.coreRoutesNames = coreRoutesNames;
	}
}
