<div class="dialog-wrapper">
    <div class="d-flex justify-content-between">
        <h1>{{ "dialog-perimeter.title" | translate }}</h1>
        <button *ngIf="perimeter" mat-flat-button (click)="removePerimeter()" class="delete-button">
            {{ 'general.delete' | translate }}<mat-icon class="ml-1">delete</mat-icon>
        </button>
    </div>
    <div [formGroup]="form" class="mt-4 perimeter-form">
        <label>{{ "dialog-perimeter.perimeter_name" | translate }} *</label><br/>
        <mat-form-field class="mb-4">
            <input type="text" formControlName="name" cdkFocusInitial matInput required maxlength="255">
        </mat-form-field>
        <br/>
        <h2 class="mb-3">{{ "dialog-perimeter.geographic_area_choice" | translate }}</h2>
        <label>{{ "dialog-perimeter.geographic_area" | translate }} *</label><br/>
        <mat-form-field class="mb-3">
            <mat-select formControlName="geographic_area_id">
                <mat-option *ngFor="let geographicArea of geographicAreas" [value]="geographicArea.id">
                    {{ geographicArea.name }}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <br/>
        <h2 class="mb-3">{{ "dialog-perimeter.affilated_countries" | translate }}</h2>

        <div class="row" formArrayName="country_checkboxes">
            <ng-container *ngFor="let country of countries; let i = index">
                <mat-checkbox color="primary" class="col-md-4 col-lg-2 mb-3" [formControlName]="i">
                    {{ country['label_' + authUser.lang_code] }}
                </mat-checkbox>
            </ng-container>
        </div>
        <div *ngIf="countries.length == 0" class="mb-5">
            <span>{{ 'general.none' | translate }}</span>
        </div>
        <button mat-flat-button color="primary" (click)="submit()" class="mr-3">
            <mat-icon>arrow_right</mat-icon>
            {{ 'general.validate' | translate }}
        </button>
        <button mat-stroked-button color="primary" (click)="close()">
            <mat-icon>arrow_right</mat-icon>
            {{ 'general.cancel' | translate }}
        </button>
    </div>
</div>