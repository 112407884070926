<div class="dialog-wrapper" [formGroup]="form">
    <div mat-dialog-title class="mb-5 d-flex align-items-center">
        <h1>{{ "user_search.dialog_add_user.title" | translate}}</h1>
    </div>
    <div mat-dialog-content class="text-center">
        <mat-form-field class="w-75">
            <mat-label>{{ "user_search.dialog_add_user.email" | translate }}</mat-label>
            <input type="text" formControlName="email" matInput cdkFocusInitial required maxlength="255" (blur)="handleBlur($event)">
        </mat-form-field>
        <mat-form-field class="w-75">
            <mat-label>{{ "user_search.dialog_add_user.igg" | translate }}</mat-label>
            <input type="text" formControlName="igg" matInput maxlength="8">
        </mat-form-field>
        <mat-form-field class="w-75">
            <mat-label>{{ "user_search.dialog_add_user.country" | translate }}</mat-label>
            <mat-select formControlName="working_country_id" required>
                <mat-option *ngFor="let country of countries" [value]="country.id">
                    {{country['label_' + currentLang]}}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field class="w-75">
            <mat-label>{{ "user_search.dialog_add_user.branch" | translate }}</mat-label>
            <mat-select formControlName="branch" required>
                <mat-option *ngFor="let branch of branches" [value]="branch.branch">
                    {{ branch.branch }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <br />
    <div mat-dialog-actions>
        <div class="w-100 text-center">
            <div>
                <button mat-raised-button color="primary" (click)="submit()" class="mr-3" [disabled]="!form.valid">
                    <mat-icon>arrow_right</mat-icon>
                    <span *ngIf="!userExists">{{ "general.add" | translate }}</span>
                    <span *ngIf="userExists">{{ "general.edit" | translate }}</span>
                </button>
                <button mat-stroked-button color="primary" (click)="close()">
                    <mat-icon>arrow_right</mat-icon>
                    {{ 'general.cancel' | translate }}
                </button>
            </div>
            <br />
        </div>
    </div>
</div>
