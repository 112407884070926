<div class="dialog-wrapper">
    <div mat-dialog-content>
        <span class="text">{{ data.text }}</span>
        <mat-form-field appearance="outline" *ngIf="data.type == 'prompt'">
            <input type="text" matInput>
        </mat-form-field>
    </div>
    <div mat-dialog-actions>
        <div style="width: 100%; text-align: center">
            <div *ngIf="data.type == 'prompt'">
                <button mat-raised-button color="primary" (click)="onClick()">
                    <mat-icon>arrow_right</mat-icon>
                    <span>{{ 'general.validate' | translate }}</span>
                </button>
                <button mat-stroked-button color="primary" (click)="onNoClick()">
                    <mat-icon>arrow_right</mat-icon>
                    <span>{{ 'general.cancel' | translate }}</span>
                </button>
            </div>
            <div *ngIf="data.type == 'confirm'">
                <br/>
                <button mat-stroked-button color="primary" class="mr-3" (click)="onClick()">
                    <mat-icon>arrow_right</mat-icon>
                    <span>{{ 'general.yes' | translate }}</span>
                </button>
                <button mat-raised-button color="primary" (click)="onNoClick()">
                    <mat-icon>arrow_right</mat-icon>
                    <span>{{ 'general.no' | translate }}</span>
                </button>
            </div>
            <div *ngIf="data.type == 'alert'">
                <br/>
                <button mat-raised-button color="primary" (click)="onClick()">
                    <mat-icon>arrow_right</mat-icon>
                    <span>OK</span>
                </button>
            </div>
        </div>
    </div>
</div>