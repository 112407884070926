import { Component, Inject, OnInit } from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

export interface DialogData {
	title: string;
	text: string;
	value: string;
	type: string;
  }

@Component({
	selector: 'app-dialog',
	templateUrl: './dialog.component.html',
	styleUrls: ['./dialog.component.scss']
  })
  export class DialogComponent implements OnInit {
	constructor(
		public dialogRef: MatDialogRef<DialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: DialogData
	) { }

	ngOnInit(): void {

	}

	onNoClick(): void {
		this.dialogRef.close();
	}

	onClick(): void {
		this.dialogRef.close(this.data.value);
	}
}
