import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import {AuthService} from '@ff/auth';
import {UserService} from '@ff/user';
import { Observable } from 'rxjs';
import {User} from 'src/app/models/user';

@Injectable({
  providedIn: 'root'
})
export class AppAdminCountryGuard implements CanActivate {
	constructor(
		public authService: AuthService,
		private userService: UserService,
		private router: Router) {
	}

	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

		try {
			const isLoggedIn = this.authService.isLoggedIn().getValue();

			if (isLoggedIn) {
				const user = new User(this.userService.getUser().getValue());

			if (user.isAdmin()) {
				return true;
			} else {
				this.gotoLogin();
			}
			} else {
				this.gotoLogin();
			}
		} catch (error) {
			this.gotoLogin();
		}
	}

	private gotoLogin(): void {
		this.router.navigate(['/login']);
	}
}
