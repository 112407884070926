export const endpoints = {
	home: '/home',
	langUpdate: '/user/lang',
	lastConnection: '/user/lastConnection',
	countries: '/countries',
	nationalities: '/nationalities',
	residences: '/residences',
	residencesTypes: '/residencesTypes',
	residencesByPerimeterForTypes: '/residencesByPerimeter/types',
	residencesByCountryForTypes: '/residencesByCountry/types',
	saveUserInfo: '/userInfo/save',
	savePersonInfo: '/personInfo/save',
	statuses: '/statuses',
	branches: '/branches',
	userRegister: '/user/register',
  userExists: '/check-user-exits',
  updateUser: '/update-user',

	// Admin endpoints
	employees: '/admin/employees',
	employeesExport: '/admin/employees/export',
	employeesByCountry: '/admin/employees/country',
	employeesByPerimeter: '/admin/employees/perimeters',
	employeesCount: '/admin/employees/count',
	administrators: '/admin/administrators',
	administratorsSuperadmins: '/admin/administrators/superadmins',
	administratorsForPerimeter: '/admin/administrators/perimeters',
	adminCountries: '/admin/countries',
	adminCountry: '/admin/adminCountry',
	adminResidences: '/admin/residences',
	adminResidencesForType: '/admin/residences/types',
	adminResidencesByCountry: '/admin/residences/countries',
	adminCountryByGeographicArea: '/admin/countries/geographicAreas',
	adminCountryByPerimeter: '/admin/countries/perimeters',
	adminNotifications: '/admin/notifications',
	adminNotificationsForCountry: '/admin/notifications/countries',
	adminSearchFilters: '/admin/administrators/searchFilters',
	dashboard: '/admin/dashboard',
	dashboardExport: '/admin/dashboard/export',
	geographicAreas: '/admin/geographicAreas',
	safetyNumbers: '/admin/safetyNumbers',
	contactMail: '/admin/contactMail',
	perimeters: '/admin/perimeters',
	fileUpload: '/admin/fileUpload',
	getFile: '/admin/getFile',
	sendNotification: '/admin/sendNotification',
    branchesByCountry: '/admin/branches/country',
    branchesByPerimeters: '/admin/branches/perimeters',

    employeesLogin: '/auth/loginWithOtherAccount'
};
