import { DialogAddExtraInfoUserComponent } from './../dialog-add-extra-info-user/dialog-add-extra-info-user.component';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import {MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { LoaderService } from '@ff/loader';
import { UserService } from '@ff/user';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin, merge, Observable } from 'rxjs';
import { map, startWith, switchMap } from 'rxjs/operators';
import { User } from 'src/app/models/user';
import { UserFilters } from 'src/app/models/user-filters';
import { UserResult } from 'src/app/models/user-result';
import { CrudService } from 'src/app/services/shared/crud.service';
import { DialogService } from 'src/app/services/shared/dialog.service';
import { UtilsService } from 'src/app/services/shared/utils.service';
import { EmployeeService } from 'src/app/services/user/employee.service';
import { DialogAssistanceComponent } from '../dialog-assistance/dialog-assistance.component';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {MatChipInputEvent} from '@angular/material/chips';
import {ElementRef} from '@angular/core';
import {DialogFiltersComponent} from '../dialog-filters/dialog-filters.component';
import {AdministratorService} from 'src/app/services/administrator.service';
import {Country} from 'src/app/models/country';
import {DialogAddUserComponent} from '../dialog-add-user/dialog-add-user.component';
import {environment} from 'src/environments/environment';

@Component({
	selector: 'app-user-list',
	templateUrl: './user-list.component.html',
	styleUrls: ['./user-list.component.scss'],
	animations: [
		trigger('detailExpand', [
			state('collapsed', style({ height: '0px', minHeight: '0' })),
			state('expanded', style({ height: '*' })),
			transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
		]),
	],
})
export class UserListComponent implements OnInit, OnChanges {
	@Input() enableDownload?: boolean;
	@Input() countryId?: number;
	@Output() employeesListUpdatedEvent = new EventEmitter<UserResult[]>();


	@ViewChild('userInput') userInput: ElementRef<HTMLInputElement>;
	@ViewChild(MatSort, { static: true }) sort: MatSort;
	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

	dataSource: MatTableDataSource<UserResult>;
	displayedColumns = ['name', 'nationality', 'residence', 'email', 'phone', 'status', 'last_connection', 'is_inside_country', 'relationship', 'other'];
	expandedRows: Array<any> = [];
	employeesCount = 0;
	results: UserResult[];
	selectedUsers = [];
	selectedIGGs = [];
	filteredResults: Observable<UserResult[]>;
	separatorKeysCodes: number[] = [ENTER, COMMA];
	selectedSavedSearch;
	nationality_checkbox_group = '';
	schengen_countries: Array < any > = [];
	countryIds: Array < any > = [];
	branches: Array<any> = [];

	authUser: User;
	form: FormGroup;
	filters: UserFilters;
	perimeter_ids: number[] = [];

	constructor(
		private employeeService: EmployeeService,
		private loaderService: LoaderService,
		private snackbar: MatSnackBar,
		private dialog: DialogService,
		private translateService: TranslateService,
		private userService: UserService,
		private administratorService: AdministratorService,
		private crudService: CrudService,
		private utilsService: UtilsService,
		private matDialog: MatDialog,
		private fb: FormBuilder
	) { }

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.countryId && !changes.countryId.firstChange) {
			this.refreshEmployees();
		}
	}

	ngOnInit(): void {
		this.initAuthUser();
		this.refreshEmployees();
		this.initFilters();
		this.initFiltersEvents();
		this.initUserAutocomplete();
	}

	initAuthUser(): void {
		this.authUser = new User(this.userService.getUser().getValue());

		if (this.authUser.isSuperAdmin()) {
			const selectedPerimeterIds = Object.values(JSON.parse(localStorage.getItem('perimeter_ids')));
			if (selectedPerimeterIds.length > 0) {
			    this.countryIds = this.authUser.perimeters.filter(perimeter => selectedPerimeterIds?.includes(perimeter.id))
				    .map(perimeter => perimeter.countries).shift().map(country => country.id);
			}
		} else {
			this.countryIds = [this.authUser.admin_country?.id];
		}
	}

	initFilters(): void {
		this.form = this.fb.group({
			userFilter: [[]],
			branches: [],
			entities: [],
			nationalities: [],
			residence_types: [],
			residences: [],
			statuses: [],
			is_inside_country: [],
			assistances: [],
			withEmployee: [{ value: true, disabled: true }],
			withSpouse: [true],
			withChild: [true],
			withPrivateVisitor: [true],
			withProfessionalVisitor: [true],
			withInternalStaff: [true],
			withExternalStaff: [true],
			withInternalTrainee: [true],
			withExternalTrainee: [true],
		});

		this.filters = new UserFilters();

		forkJoin([
			this.crudService.getNationalities(),
			this.crudService.getResidencesTypes(),
			this.crudService.getStatuses(),
		]).subscribe(([nationalities, residencesTypes, statuses]) => {
			this.filters.nationalities = nationalities;
			this.filters.residenceTypes = residencesTypes;
			this.filters.statuses = statuses;
		}, err => {
			this.snackbar.open(err);
		});

		this.initBranches();
	}

	initBranches(): void {
		if (this.authUser.isAdmin()) {
			this.crudService.getBranchesByCountry().subscribe(branches => {
				this.branches = branches;
				this.setBranchesFilter(branches);
			});
		} else if (this.authUser.isSuperAdmin()) {
			this.crudService.getBranchesByPerimeters().subscribe(branches => {
				this.branches = branches;
				this.setBranchesFilter(branches);
			});
		}
	}

	setBranchesFilter(branches: string[]): void {
		this.filters.branches = branches.map((branch: any) => {
			if (branch.branch.indexOf('/') > -1) {
				branch = branch.branch.substring(0, branch.branch.indexOf('/'));
			} else {
				branch = branch.branch;
			}
			return branch;
		});

		this.filters.branches = [...new Set(this.filters.branches)];
	}

	setEntitiesFilter(branches: string[]): void {
		this.filters.entities = branches.map((branch: any) => {
			let entity = '';
			if (branch.branch.indexOf('/') > -1) {
				entity = branch.branch.substring(branch.branch.lastIndexOf('/') + 1, branch.branch.length);
			}
			return entity;
		});

		this.filters.entities = [...new Set(this.filters.entities)].filter((entity) => entity);
	}

	handleFilterEvent(): void {
		this.loaderService.show();

		const filterData = {
			filters: this.form.value,
			perimeter_ids: Object.values(JSON.parse(localStorage.getItem('perimeter_ids')))
		}
		
		this.employeeService.filter(filterData).subscribe((response: UserResult[]) => {
			if (response != null) {
				this.results = response;
				this.dataSource = new MatTableDataSource(this.results);
				this.dataSource.sort = this.sort;
				this.dataSource.paginator = this.paginator;

				this.dataSource.filterPredicate = this.filterPredicate();
				this.updateEmployeesCount();
				this.updateEmployeesList();
			}
			this.loaderService.hide();
		}, error => {
			this.snackbar.open(error);
			this.loaderService.hide();
		});
	}

	initFiltersEvents(): void {
		merge(
			this.form.controls.branches.valueChanges,
			this.form.controls.entities.valueChanges,
			this.form.controls.nationalities.valueChanges,
			this.form.controls.residence_types.valueChanges,
			this.form.controls.residences.valueChanges,
			this.form.controls.statuses.valueChanges,
			this.form.controls.assistances.valueChanges
		).subscribe((e) => {
      		//this.dataSource.filter = this.form.getRawValue();
			//this.updateEmployeesCount();
			//this.updateEmployeesList();
		});

		merge(
			this.form.controls.withEmployee.valueChanges,
			this.form.controls.withSpouse.valueChanges,
			this.form.controls.withChild.valueChanges,
			this.form.controls.withPrivateVisitor.valueChanges,
			this.form.controls.withProfessionalVisitor.valueChanges,
			this.form.controls.withInternalStaff.valueChanges,
			this.form.controls.withExternalStaff.valueChanges,
			this.form.controls.withInternalTrainee.valueChanges,
			this.form.controls.withExternalTrainee.valueChanges,
			this.form.controls.is_inside_country.valueChanges
		).subscribe(() => {
      		/*this.dataSource.filter = this.form.getRawValue();
			this.filterUserRelations(this.form.getRawValue());
			this.updateEmployeesCount();
			this.updateEmployeesList();*/
		});

		this.form.controls.residence_types.valueChanges.pipe(
			switchMap((residence_type_ids) => {
				this.filters.residences = [];
				this.form.controls.residences.setValue([]);
				return this.crudService.getResidencesForResidenceTypes(this.authUser, residence_type_ids);
			})
		).subscribe(response => {
			this.filters.residences = response;
		});

		this.form.controls.branches.valueChanges.subscribe(selectedBranches => {
			let filteredBranches = [];
			filteredBranches = this.branches.filter(branch => {
				return selectedBranches.find(selected => !branch.branch.indexOf(selected));
			});
			this.setEntitiesFilter(filteredBranches);
		});
	}

	initUserAutocomplete(): void {
		this.filteredResults = this.form.get('userFilter').valueChanges.pipe(
			startWith(''),
			map(value => {
				return this.filterUserName(value);
			})
		);
	}

	updateExpandedRows(row): void {
		if (this.expandedRows.includes(row)) {
			this.expandedRows.splice(this.expandedRows.indexOf(row), 1);
		} else {
			this.expandedRows.push(row);
		}
	}

	refreshEmployees(): void {
		this.loaderService.show();
		const getEmployeesCall = this.countryId ? this.employeeService.getByCountry(this.countryId) :
			this.employeeService.getByUserRole(this.authUser);
		getEmployeesCall.subscribe((response: UserResult[]) => {
			if (response != null) {
				this.results = response;
				this.dataSource = new MatTableDataSource(this.results);
				this.dataSource.sort = this.sort;
				this.dataSource.paginator = this.paginator;

				this.dataSource.filterPredicate = this.filterPredicate();
				this.updateEmployeesCount();
				this.updateEmployeesList();
			}
			this.loaderService.hide();
		}, error => {
			this.snackbar.open(error);
			this.loaderService.hide();
		});
	}

	refreshAuthUser(): void {
		this.userService.fetch().then((data) => {
			this.authUser = new User(data);
		});
	}

	removeEmployee(id: number): void {
		const dialogRef = this.dialog.showConfirm(
			this.translateService.instant('general.messages.confirm_delete')
		);

		dialogRef.afterClosed().subscribe((confirm: boolean) => {
			if (confirm === true) {
				this.loaderService.show();
				this.employeeService.delete(id).subscribe(response => {
					this.loaderService.hide();
					if (response != null) {
						this.snackbar.open(this.translateService.instant('general.messages.item_deleted'));
						this.refreshEmployees();
					}
				}, error => {
					this.snackbar.open(error);
					this.loaderService.hide();
				});
			}
		});
	}

	openLoginWithOtherAccountDialog(id: number): void {
		const dialogRef = this.dialog.showConfirm(
			this.translateService.instant('user_search.labels.confirm_account_change')
		);

		dialogRef.afterClosed().subscribe((confirm: boolean) => {
			if (confirm === true) {
				this.loginWithOtherAccount(id);
			}
		});
	}

	loginWithOtherAccount(id: number): void {
		this.administratorService.loginWithOtherAccount(id).subscribe((response) => {
			window.open(response.data.redirect, '_blank');
		}, (error) => {
			console.log(error);
		});
	}

	selectUser(event: MatAutocompleteSelectedEvent): void {
    let initData:UserResult[] = JSON.parse(JSON.stringify(this.results));
		this.selectedUsers.push(event.option.value);
		this.userInput.nativeElement.value = '';
		this.form.controls.userFilter.setValue(this.selectedUsers);
    this.selectedIGGs = this.selectedUsers.map(user => user.igg);



    this.dataSource.data = initData.filter((user) => {
      return this.selectedIGGs.includes(user.igg);
    })
    //this.dataSource.filter = this.form.getRawValue();
		this.updateEmployeesCount();
		this.updateEmployeesList();
	}

	addUser(event: MatChipInputEvent): void {
		const input = event.input;
		const value = event.value;

		if ((value || '').trim()) {
			this.selectedUsers.push(value.trim());
		}

		if (input) {
			input.value = '';
		}

		this.form.controls.userFilter.setValue('');
	}


	removeSelectedUser(selectedUser: UserResult): void {
		const index = this.selectedUsers.indexOf(selectedUser);
    let initData:UserResult[] = JSON.parse(JSON.stringify(this.results));

		if (index >= 0) {
			this.selectedUsers.splice(index, 1);
		}

    if (this.selectedUsers.length === 0) {
      this.dataSource.data = initData;
    }else{
      this.selectedIGGs = this.selectedUsers.map(user => user.igg);
      this.dataSource.data = initData.filter((user) => {
        return this.selectedIGGs.includes(user.igg);
      })
    }

		this.form.controls.userFilter.setValue(this.selectedUsers);
		//this.dataSource.filter = this.form.getRawValue();
		this.updateEmployeesCount();
		this.updateEmployeesList();
	}

	toggleIsInsideCountry($event, user: UserResult): void {
		$event.preventDefault();
		const dialogRef = this.dialog.showConfirm(
			this.translateService.instant('dialog-country.confirm_toggle')
		);

		dialogRef.afterClosed().subscribe((confirm: boolean) => {
			if (confirm === true) {
				this.loaderService.show();
				this.employeeService.updateIsInCountryByAdmin(user.id, !user.is_inside_country).subscribe(response => {
					this.loaderService.hide();
					if (response != null) {
						this.snackbar.open(this.translateService.instant('general.messages.info_saved'));
						this.refreshEmployees();
					}
				}, error => {
					this.snackbar.open(error);
					this.loaderService.hide();
				});
			}
		});
	}

	openAddUserDialog(): void {
		const dialogRef = this.matDialog.open(DialogAddUserComponent);

		dialogRef.afterClosed().subscribe((user_id) => {
			if (user_id) {
				this.refreshEmployees();
				this.loginWithOtherAccount(user_id);
			}
		});
	}

	openAssistanceDialog(user: UserResult): void {
		// $event.stopPropagation();
		const dialogRef = this.matDialog.open(DialogAssistanceComponent, {
			data: { user }
		});

		dialogRef.afterClosed().subscribe((refresh) => {
			if (refresh) {
				this.refreshEmployees();
			}
		});
	}

	openUnlockDialog(user: UserResult): void {
		const dialogRef = this.dialog.showConfirm(
			this.translateService.instant('dialog-assistance.confirm_unlock')
		);

		dialogRef.afterClosed().subscribe((confirm: boolean) => {
			if (confirm === true) {
				this.loaderService.show();
				this.employeeService.unlockAssistance(user.id).subscribe(response => {
					this.loaderService.hide();
					if (response != null) {
						this.snackbar.open(this.translateService.instant('general.messages.info_saved'));
						this.refreshEmployees();
					}
				}, error => {
					this.snackbar.open(error);
					this.loaderService.hide();
				});
			}
		});
	}

	openSaveFiltersDialog(): void {
		const dialogRef = this.matDialog.open(DialogFiltersComponent, {
			data: {
				filters: this.form.getRawValue()
			}
		});

		dialogRef.afterClosed().subscribe((refresh) => {
			if (refresh) {
				// We refresh the auth user to get its new search filters
				this.refreshAuthUser();
			}
		});
	}

	deleteSavedSearch(id: number): void {
		const dialogRef = this.dialog.showConfirm(
			this.translateService.instant('general.messages.confirm_delete')
		);

		dialogRef.afterClosed().subscribe((confirm: boolean) => {
			if (confirm === true) {
				this.loaderService.show();
				this.administratorService.deleteSavedSearch(id).subscribe(response => {
					this.loaderService.hide();
					this.snackbar.open(this.translateService.instant('general.messages.item_deleted'));
					this.refreshAuthUser();
				}, error => {
					this.snackbar.open(error);
					this.loaderService.hide();
				});
			}
		});
	}

	getInfo(field: string): string {
		return field != null ? field : '- -';
	}

	updateEmployeesCount(): void {
		let count = 0; // this.dataSource.filteredData.length;
		this.dataSource.filteredData.forEach((user: UserResult) => {
			count = count + user.user_relations?.length;
		});

		this.employeesCount = count;
	}

	updateEmployeesList(): void {
		this.employeesListUpdatedEvent.emit(this.dataSource.filteredData);
	}

	filterHasResults(searchFilter, data, startWith: boolean = false, endWith: boolean = false, filterType = null): boolean {
		if (startWith) { data = data?.indexOf('/') != -1 ? data?.substring(0, data?.indexOf('/')) : data; }
		if (endWith) { data = data?.substring(data?.lastIndexOf('/') + 1, data?.length); }

    let itHas = false;
    if (filterType && filterType === "standard"){
      itHas = searchFilter?.includes(data);
		}

		let hasAssistanceInHisCrew = false;
		if (filterType && filterType === "assistances"){
			const hasAssistance = data.user_relations.filter(user => searchFilter?.some(x => parseInt(x) == parseInt(user.assistance_group)))
			if(hasAssistance.length > 0) hasAssistanceInHisCrew = true;
		}

    let hasBranche = false;
    if (filterType && filterType === "branches") {
      hasBranche = searchFilter?.includes(data) ? true : false;
    }

    let hisInsideCountry = false;
    if (filterType && filterType === "isInsideCountry") {
      hisInsideCountry = data.is_inside_country == searchFilter;
    }

    //(data?.user_relations ? hasAssistanceInHisCrew : searchFilter?.includes(data))
		return (searchFilter == null || searchFilter?.length === 0) || hasAssistanceInHisCrew || hisInsideCountry || hasBranche || itHas ;
	}

	checkBoxHasResults(searchFilter, data, id): boolean {
		return (searchFilter === true && data.length > 0 && data.find((relation) => relation.relation_type_id === id));
	}

	filterUserRelations(searchFilter): void {
		if (this.results != null && this.results.length > 0) {
			this.dataSource.data = JSON.parse(JSON.stringify(this.results)); // get a copy of the initial result
			this.dataSource.data.forEach((userResult: UserResult) => {
				userResult.user_relations = userResult.user_relations?.filter((relation: UserResult) => {
					return ((relation.relation_type_id === 1 && searchFilter.withSpouse) ||
						(relation.relation_type_id === 2 && searchFilter.withChild) ||
						(relation.relation_type_id === 3 && searchFilter.withPrivateVisitor) ||
						(relation.relation_type_id === 4 && searchFilter.withProfessionalVisitor && relation.assistance_group === 1) ||
						(relation.category === 'personnal_igg_j' && searchFilter.withInternalStaff) ||
						(relation.category === 'personnal_igg_l' && searchFilter.withExternalStaff) ||
						(relation.category === 'trainee_igg_j' && searchFilter.withInternalTrainee) ||
						(relation.category === 'trainee_igg_l' && searchFilter.withExternalTrainee));
				});
			});

      this.dataSource.data = this.dataSource.data.filter(user => user.user_relations.length > 0)
		}
	}

	isInsideCountry(searchFilter, data): boolean {
		const hasRelationInsideCountry = data.user_relations?.find((relation) => searchFilter?.includes((relation.is_inside_country)));

		return (searchFilter == null || searchFilter?.length === 0) || hasRelationInsideCountry || searchFilter?.includes(data.is_inside_country);
	}

	filterPredicate(): any {

		const myFilterPredicate = (data, searchFilter): boolean => {
			return (this.filterHasResults(searchFilter.nationalities, data.nationality?.id, false, false, "standard") ||
				this.filterHasResults(searchFilter.nationalities, data.second_nationality?.id, false, false, "standard")) &&
				this.filterHasResults(searchFilter.residence_types, data.residence?.residence_type_id, false, false, "standard") &&
				this.filterHasResults(searchFilter.statuses, data.status?.id, false, false, "standard") &&
				this.filterHasResults(searchFilter.assistances, data, false, false, "assistances") &&
				this.filterHasResults(searchFilter.branches, data.branch, true, false, "branches") &&
				this.filterHasResults(searchFilter.entities, data.branch, false, true, "standard") &&
				this.filterHasResults(searchFilter.residences, data.residence?.id, false, false, "standard") &&
				this.filterHasResults(searchFilter.userFilter, data) &&
        this.filterHasResults(searchFilter.is_inside_country, data, false, false, "isInsideCountry") &&
				data.user_relations?.length > 0;
		};

		return myFilterPredicate;
	}

	filterUserName(value: any): UserResult[] {
		if (typeof value !== 'object') {
      const filterNameValue = value.name ? value.name.toLowerCase() : value?.toLowerCase();
      const filterIggValue = value.igg ? value.igg.toLowerCase() : value?.toLowerCase();

			return this.results?.filter(option =>
				option.name.toLowerCase().includes(filterNameValue) ||
				option.igg.toLowerCase().includes(filterIggValue)
			);
		}
	}

	updateSelectedOptions($event): void {
		if (this.schengen_countries.length === 0) {
			this.schengen_countries = this.filters.nationalities.filter(nationality => nationality.is_in_schengen)
			.map(nationality => nationality.id);
		}

		if (this.nationality_checkbox_group === 'show_schengen' && !$event.value.includes('show_schengen') ||
			this.nationality_checkbox_group === 'show_residence_country' && !$event.value.includes('show_residence_country') ||
			this.nationality_checkbox_group === 'show_other_country' && !$event.value.includes('show_other_country')
		) {
			this.nationality_checkbox_group = '';
			this.form.controls.nationalities.setValue([]);
		}

		if ($event.value.includes('show_schengen')) {
			const selection = [...this.schengen_countries];
			this.nationality_checkbox_group = 'show_schengen';
			this.form.controls.nationalities.setValue(selection);
		}

		if ($event.value.includes('show_residence_country')) {
			const selection = [...this.countryIds];
			this.nationality_checkbox_group = 'show_residence_country';
			this.form.controls.nationalities.setValue(selection);
		}

		if ($event.value.includes('show_other_country')) {
			const selection: any = this.filters.nationalities.filter(nationality => !this.countryIds.includes(nationality.id))
														.map(nationality => nationality.id);
			this.nationality_checkbox_group = 'show_other_country';
			this.form.controls.nationalities.setValue(selection);
		}
	}

	loadSavedSearchFilters($event): void {
		if ($event.value != '') {
			const filters = JSON.parse($event.value.filters);
			this.selectedSavedSearch = $event.value.name;

			filters.userFilter = [];
			this.form.setValue(filters);
			if (filters.residences != null) {
				this.form.controls.residences.setValue(filters.residences);
			}
		} else {
			this.selectedSavedSearch = '';
			this.form.reset();
			this.form.patchValue({
				withSpouse: [true],
				withChild: [true],
				withPrivateVisitor: [true],
				withProfessionalVisitor: [true],
				withInternalStaff: [true],
				withExternalStaff: [true],
				withInternalTrainee: [true],
				withExternalTrainee: [true],
			});
		}
	}

	userAutocompleteDisplayFn(user: UserResult): string {
		return user ? user.name : '';
	}

	exportEmployees(): void {
		this.loaderService.show();
		this.employeeService.export(this.getFilteredUsers()).subscribe((res) => {
			this.utilsService.exportXlsx(res, 'Employees');
			this.loaderService.hide();
		}, error => {
			this.loaderService.hide();
			this.snackbar.open(error);
		});
	}

	private getFilteredUsers() {
		const person_info_ids = [];
		this.dataSource.filteredData.forEach((employee: UserResult) => {
			employee.user_relations.forEach((relation: UserResult) => {
				person_info_ids.push(relation.id);
			});
		});

		return person_info_ids;
	}

  completePersonnelPrivateInformation(id: number): void {
    const dialogRef = this.matDialog.open(DialogAddExtraInfoUserComponent, {
			data: id
		});

		dialogRef.afterClosed().subscribe((refresh) => {
			if (refresh) {
				this.refreshEmployees();
			}
		});
  }

  resetFilters(): void {
	this.form.reset({
		userFilter: [[]],
		branches: [],
		entities: [],
		nationalities: [],
		residence_types: [],
		residences: [],
		statuses: [],
		is_inside_country: [],
		assistances: [],
		withEmployee: [{ value: true, disabled: true }],
		withSpouse: [true],
		withChild: [true],
		withPrivateVisitor: [true],
		withProfessionalVisitor: [true],
		withInternalStaff: [true],
		withExternalStaff: [true],
		withInternalTrainee: [true],
		withExternalTrainee: [true],
	})
  }
}
