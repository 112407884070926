import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AuthService } from '@ff/auth';
import { UserService } from '@ff/user';
import { Observable } from 'rxjs';
import { absoluteRoutesNames } from './../../modules/shared/absolute.routes';

@Injectable({
  providedIn: 'root'
})
export class AppLoggedInGuard implements CanActivate {

  constructor(
	public authService: AuthService,
	private userService: UserService,
	private router: Router) {
  }

  canActivate(
	next: ActivatedRouteSnapshot,
	state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
	try {

		if ([absoluteRoutesNames.LOGIN, absoluteRoutesNames.LOGOUT].includes(state.url)) {
		return true;
		}

		const isUserLoggedIn = this.authService.isLoggedIn().getValue();

		if (!isUserLoggedIn) {
		this.gotoLogin();
		}

		return true;

	} catch (error) {
		this.gotoLogin();
	}
  }

  private gotoLogin(): void {
	this.router.navigate([absoluteRoutesNames.LOGIN]);
  }
}
