import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map, timeout } from 'rxjs/operators';
import { Country } from 'src/app/models/country';
import { Message } from 'src/app/models/message';
import { Perimeter } from 'src/app/models/perimeter';
import { Recipient } from 'src/app/models/recipient';
import { User } from 'src/app/models/user';
import { endpoints } from 'src/environments/endpoints';
import { environment } from 'src/environments/environment';

@Injectable({
	providedIn: 'root'
})
export class CrudService {
	constructor(
		private http: HttpClient
	) { }

	updateUserLang(langCode: string): Observable<any> {
		return this.http.get(environment.api.host + endpoints.langUpdate + '/' + langCode).pipe(
			map(
				(res: any) => res.data.user
			),
			catchError(this.handleError)
		);
	}

	updateAdminCountry(admin_country_id: number): Observable<any> {
		return this.http.put(environment.api.host + endpoints.adminCountry, { admin_country_id }).pipe(
			catchError(this.handleError)
		);
	}

	updateLastConnection(): Observable<any> {
		return this.http.post(environment.api.host + endpoints.lastConnection, null).pipe(
			catchError(this.handleError)
		);
	}

	getGeographicAreas(): Observable<any> {
		return this.http.get(environment.api.host + endpoints.geographicAreas).pipe(
			map(
				(res: any) => res.data.geographicAreas
			),
			catchError(this.handleError)
		);
	}

	getCountries(): Observable<any> {
		return this.http.get(environment.api.host + endpoints.countries).pipe(
			map(
				(res: any) => res.data.countries
			),
			catchError(this.handleError)
		);
	}

	getDashboardData(user: User): Observable<any> {
		let perimeter_ids: number[] = [];

		if (user.isSuperAdmin()) {
			perimeter_ids = Object.values(JSON.parse(localStorage.getItem('perimeter_ids')));
		}

		return this.http.post(environment.api.host + endpoints.dashboard, { perimeter_ids }).pipe(
			map(
				(res: any) => res.data
			),
			catchError(this.handleError)
		);
	}

	getAuthConfig(): Observable<any> {
		return this.http.get(environment.api.host + '/auth/config').pipe(
			catchError(err => this.handleError(err))
		);
	}

	exportDashboardData(user: User): Observable<Blob> {
		let perimeter_ids: number[] = [];

		if (user.isSuperAdmin()) {
			perimeter_ids = Object.values(JSON.parse(localStorage.getItem('perimeter_ids')));
		}

		return this.http.post(environment.api.host + endpoints.dashboardExport,
			{ perimeter_ids },
			{ responseType: 'blob' }
		).pipe(
			map(res => res),
			catchError(this.handleError)
		);
	}

	getResidencesTypes(): Observable<any> {
		return this.http.get(environment.api.host + endpoints.residencesTypes).pipe(
			map(
				(res: any) => res.data.residences_types
			),
			catchError(this.handleError)
		);
	}

	getResidences(workingCountryId: number, residenceTypeId: number): Observable<any> {
		return this.http.get(environment.api.host + endpoints.residences + '/' + workingCountryId + '/' + residenceTypeId).pipe(
			map(
				(res: any) => res.data.residences
			),
			catchError(this.handleError)
		);
	}

	getResidencesForResidenceTypes(user, residenceTypeIds): Observable<any> {
		if (residenceTypeIds != null) {
			if (user.isAdmin()) {
				return this.getResidencesByAdminCountryForResidenceTypes(residenceTypeIds);
			}

			if (user.isSuperAdmin()) {
				const perimeter_ids: number[] = Object.values(JSON.parse(localStorage.getItem('perimeter_ids')));
				return this.getResidencesByPerimeterForResidenceTypes(perimeter_ids, residenceTypeIds);
			}
		} else {
			return of([]);
		}
	}

	getResidencesByPerimeterForResidenceTypes(perimeterIds, residenceTypeIds): Observable<any> {
		return this.http.post(environment.api.host + endpoints.residencesByPerimeterForTypes,  { perimeterIds, residenceTypeIds }).pipe(
			map(
				(res: any) => res.data.residences
			),
			catchError(this.handleError)
		);
	}

	getResidencesByAdminCountryForResidenceTypes(residenceTypeIds): Observable<any> {
		return this.http.post(environment.api.host + endpoints.residencesByCountryForTypes,  { residenceTypeIds }).pipe(
			map(
				(res: any) => res.data.residences
			),
			catchError(this.handleError)
		);
	}

	getNotifications(countryId): Observable<Message[]> {
		return this.http.get(environment.api.host + endpoints.adminNotificationsForCountry + '/' + countryId).pipe(
			map(
				(res: any) => res.data.notifications
			),
			catchError(this.handleError)
		);
	}

	getNotificationRecipients(notificationId: number): Observable<Recipient[]> {
		return this.http.get(environment.api.host + endpoints.adminNotifications + `/${notificationId}/recipients`).pipe(
			map(
				(res: any) => res.data.recipients
			),
			catchError(this.handleError)
		);
	}


	getPerimeters(): Observable<Perimeter[]> {
		return this.http.get(environment.api.host + endpoints.perimeters).pipe(
      timeout(3600),
			map(
				(res: any) => res.data.perimeters
			),
			catchError(this.handleError)
		);
	}

	getNationalities(): Observable<Country[]> {
		return this.http.get(environment.api.host + endpoints.nationalities).pipe(
			map(
				(res: any) => res.data.nationalities.map(country => new Country(country))
			),
			catchError(err => this.handleError(err))
		);
	}

	getStatuses(): Observable<any> {
		return this.http.get(environment.api.host + endpoints.statuses).pipe(
			map(
				(res: any) => res.data.statuses
			),
			catchError(this.handleError)
		);
	}

	getBranches(): Observable<any> {
		return this.http.get(environment.api.host + endpoints.branches).pipe(
			map(
				(res: any) => res.data.branches
			),
			catchError(this.handleError)
		);
	}

    getBranchesByCountry(): Observable<any> {
        return this.http.get(environment.api.host + endpoints.branchesByCountry).pipe(
			map(
				(res: any) => res.data.branches
			),
			catchError(this.handleError)
		);
    }

    getBranchesByPerimeters(): Observable<any> {
        const perimeter_ids: number[] = Object.values(JSON.parse(localStorage.getItem('perimeter_ids')));
        return this.http.post(environment.api.host + endpoints.branchesByPerimeters, { perimeter_ids }).pipe(
			map(
				(res: any) => res.data.branches
			),
			catchError(this.handleError)
		);
    }

	putPerimeter(perimeterInfo: any, perimeterId: number): Observable<any> {
		return this.http.put(environment.api.host + endpoints.perimeters + '/' + perimeterId, perimeterInfo).pipe(
			map(
				(res: any) => res.data.perimeter
			),
			catchError(this.handleError)
		);
	}

	saveUserInfo(userInfo: any): Observable<any> {
		return this.http.post(environment.api.host + endpoints.saveUserInfo, userInfo).pipe(
			map(
				(res: any) => res.data.user
			),
			catchError(this.handleError)
		);
	}

	savePersonInfo(personInfo: any): Observable<any> {
		return this.http.post(environment.api.host + endpoints.savePersonInfo, personInfo).pipe(
			map(
				(res: any) => res.data.user
			),
			catchError(this.handleError)
		);
	}

	sendNotification(notifInfo: any): Observable<any> {
		return this.http.post(environment.api.host + endpoints.sendNotification, notifInfo).pipe(
			map(
				(res: any) => res
			),
			catchError(this.handleError)
		);
	}

	deletePerimeter(id: number): Observable<any> {
		return this.http.delete(environment.api.host + endpoints.perimeters + '/' + id).pipe(
			catchError(this.handleError)
		);
	}

	deleteNotification(id: number): Observable<any> {
		return this.http.delete(environment.api.host + endpoints.adminNotifications + '/' + id).pipe(
			catchError(this.handleError)
		);
	}

	registerUser(data: any): Observable<any> {
		return this.http.post(environment.api.host + endpoints.userRegister, data).pipe(
			catchError(err => this.handleError(err))
		);
	}

  checkUserExists(email: string): Observable<any> {
    return this.http.post(environment.api.host + endpoints.userExists, {email}).pipe(
      catchError(err => this.handleError(err))
    );
  }

  updateUser(data: User, id: number): Observable<any> {
    return this.http.put(environment.api.host + endpoints.updateUser + '/' + id, data).pipe(
      catchError(err => this.handleError(err))
    );
  }

	protected handleError(error: any): Promise<any> {
		if (error.status === 401) {
			window.location.reload();
		}
		return Promise.reject(error?.error?.message || error?.message || error);
	}
}
