import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MatDialogRef} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {LoaderService} from '@ff/loader';
import {UserService} from '@ff/user';
import {TranslateService} from '@ngx-translate/core';
import {Country} from 'src/app/models/country';
import {User} from 'src/app/models/user';
import {CountryService} from 'src/app/services/country.service';
import {CrudService} from 'src/app/services/shared/crud.service';

@Component({
    selector: 'app-dialog-add-user',
    templateUrl: './dialog-add-user.component.html',
    styleUrls: ['./dialog-add-user.component.scss']
})
export class DialogAddUserComponent implements OnInit {
    form: FormGroup;
    authUser: User;
    branches = [];
    countries: Country[] = [];
    isLoading: boolean = false;
    userExists: boolean = false;
    userFound: User;

    constructor(
        private fb: FormBuilder,
        private crudService: CrudService,
        private loaderService: LoaderService,
        private snackbar: MatSnackBar,
        private translateService: TranslateService,
        private countryService: CountryService,
        private userService: UserService,
        public dialogRef: MatDialogRef<DialogAddUserComponent>,
    ) { }

    ngOnInit(): void {
        // TODO: QUI PEUT AJOUTER UN USER ?
        this.authUser = new User(this.userService.getUser().getValue());

        this.initForm();
        this.getBranches();
        this.getCountries();
    }

    get currentLang(): string {
		return this.translateService.currentLang;
	}

    getBranches(): void {
        this.loaderService.show();
        this.crudService.getBranches().subscribe((response) => {
            this.branches = response;
            this.loaderService.hide();
        }, (error) => {
            this.loaderService.hide();
        });
    }

    getCountries(): void {
		// tslint:disable-next-line: radix
        if (this.authUser.isSuperAdmin()) {
            const perimeter_ids: number[] = Object.values(JSON.parse(localStorage.getItem('perimeter_ids')));

            this.countryService.getByPerimeters(perimeter_ids).subscribe((response: Country[]) => {
                this.countries = response;
            });
        } else {
            console.log(this.authUser.admin_countries);
            this.countries = this.authUser.admin_countries;
        }
	}

    initForm(): void {
        const totalEmailValidator: Validators = Validators.pattern('^[a-zA-Z0-9_.+-]+@(?:(?:[a-zA-Z0-9-]+\.)?[a-zA-Z]+\.)?(totalenergies|as24|hutchinson|tft-services|csl|tadiran-batt|saftamerica|saftbatteries|totalgaz|smspp)([a-zA-Z-\.]+)?\.[a-zA-Z]+$');

		this.form = this.fb.group({
            email: ['', [Validators.required]],
			igg: ['', [Validators.maxLength(8)]],
            working_country_id: ['', [Validators.required]],
            branch: ['', [Validators.required]],
		});
	}

    submit(): void {
        if (this.form.valid && !this.loaderService.isVisible()) {
			this.loaderService.show();

			if (!this.userExists) {
        this.crudService.registerUser(this.form.getRawValue()).subscribe(
          (response: any) => {
            this.loaderService.hide();
            if (response != null) {
              this.snackbar.open(this.translateService.instant(response.message));
            }
            this.dialogRef.close(response.data.id);
          }, (error: any) => {
            this.snackbar.open(this.translateService.instant(error));
            this.loaderService.hide();
          }
        );
      } else {
        this.crudService.updateUser(this.form.getRawValue(), this.userFound.id).subscribe(
          (response: any) => {
            this.loaderService.hide();
            if (response != null) {
              this.snackbar.open(this.translateService.instant(response.message));
            }
            this.dialogRef.close(response.data.id);
          }, (error: any) => {
            this.snackbar.open(this.translateService.instant(error));
            this.loaderService.hide();
          }
        );
      }
		}
    }

    close(): void {
		this.dialogRef.close();
	}

  handleBlur(e: HTMLInputElement): void{
    if (this.form.controls.email.value.length > 0) {
      this.crudService.checkUserExists(this.form.controls.email.value).subscribe(
        (response: any) => {
          if (response.data != null) {
            this.userExists = true;
            this.userFound = response.data;

            this.form.setValue({
              email: response.data.email,
              igg: response.data.igg,
              working_country_id: response.data.person_info.working_country_id,
              branch: response.data.branch
            });

            this.snackbar.open("User already exists but not finished, you can continue fill his data");
          }
        },
        (error: any) => console.log(error)
      );
    }
  }

}
