import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { FileSaverService } from 'ngx-filesaver';

@Injectable({
	providedIn: 'root'
})
export class UtilsService {
	constructor(
		private datePipe: DatePipe,
		private fileSaver: FileSaverService
	) { }

	downloadFile(data, filename: string, dataIsBlob = false): void {
		if (!dataIsBlob) { data = this.dataURItoBlob(data); }

		const blob = new Blob([data], { type: data.type });
	 	this.fileSaver.save(blob, filename);
	}

	exportCsv(res): void {
		const todayDate = this.datePipe.transform(new Date(), 'yyyyMMdd');
		const data = res;

		const json2csv = require('json2csv').parse;
		const options = {
			delimiter: ';',
			prependHeader: true,
			sortHeader: false,
			trimHeaderValues: true,
			trimFieldValues: true,
			escapedQuote: true,
			quote: '',
			withBOM: true,
		};
		const csv = json2csv(data, options);
		const blobdata = new Blob(['\ufeff', csv], { type: 'text/csv' });
		const link = document.createElement('a');
		link.setAttribute('href', window.URL.createObjectURL(blobdata));
		link.setAttribute('download', 'Export - ' + todayDate + '.csv');
		document.body.appendChild(link);
		link.click();
	}

	exportXlsx(blob: Blob, fileName: string): void {
		const todayDate = this.datePipe.transform(new Date(), 'yyyyMMdd');
		this.fileSaver.save(blob, `${fileName} export - ${todayDate}.xlsx`);
	}

	private dataURItoBlob(dataURI): Blob {
		const byteString = atob(dataURI.split(',')[1]);
		const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

		const ab = new ArrayBuffer(byteString.length);
		const ia = new Uint8Array(ab);

		for (let i = 0; i < byteString.length; i++) {
			ia[i] = byteString.charCodeAt(i);
		}

		const blob = new Blob([ab], { type: mimeString });
		return blob;
	}
}
