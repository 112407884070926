<!-- ff-theme is an invisible component that applies "Total" CSS theme from @ff/angular-core -->
<ff-theme>
    <app-header></app-header>
    <mat-drawer-container autosize>
        <mat-drawer #drawer mode="side" [opened]="authUser?.isAdmin() || authUser?.isSuperAdmin()" *ngIf="userService.getUser() | async">
            <app-navigation></app-navigation>
        </mat-drawer>
        <mat-drawer-content>
            <main>
                <router-outlet (activate)="onActivate($event)"></router-outlet>
                <div *ngIf="userService.getUser() | async">
                    <div *ngIf="!authUser?.isAdmin() && !authUser?.isSuperAdmin()" class="d-flex align-items-center justify-content-center h-100">
                        {{ "general.messages.admin_required" | translate }}
                    </div>
                </div>
            </main>
        </mat-drawer-content>
    </mat-drawer-container>
</ff-theme>