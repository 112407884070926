import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LoaderService } from '@ff/loader';
import { TranslateService } from '@ngx-translate/core';
import { UserResult } from 'src/app/models/user-result';
import { EmployeeService } from 'src/app/services/user/employee.service';
import { DialogPerimeterComponent } from '../dialog-perimeter/dialog-perimeter.component';

@Component({
  selector: 'app-dialog-add-extra-info-user',
  templateUrl: './dialog-add-extra-info-user.component.html',
  styleUrls: ['./dialog-add-extra-info-user.component.scss'],
})
export class DialogAddExtraInfoUserComponent implements OnInit {
  form: FormGroup;
  user: UserResult;
  id: number;
  isBtnValid:boolean = false;

  constructor(
    private fb: FormBuilder,
    private employeeService: EmployeeService,
    private loaderService: LoaderService,
    private snackBar: MatSnackBar,
    private translateService: TranslateService,
    public dialogRef: MatDialogRef<DialogPerimeterComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.id = this.data;
    this.initForm();
    this.getPersonTarget(this.id);
  }

  getPersonTarget(id: number): void {
    this.employeeService.getPersonById(id).subscribe(
      (res) => {
        this.user = res['data'];
        this.isBtnValid = !Object.values(this.user).every(o => o === null);
        this.form.setValue({
          compositions: this.user?.compositions,
          addresses: this.user?.addresses,
          trips: this.user.trips,
          diverses_info: this.user?.diverses_info,
          diverses_info_client: this.user?.diverses_info_client,
        });
      },
      (error) => console.error(error)
    );
  }

  initForm(): void {
    this.form = this.fb.group({
      compositions: ['', []],
      addresses: ['', []],
      trips: ['', []],
      diverses_info: ['', []],
      diverses_info_client: ['', []],
    });
  }

  checkValid() {
    if(this.form.get('compositions').value?.length > 0 || this.form.get('addresses').value?.length > 0 || this.form.get('trips').value?.length > 0 || this.form.get('diverses_info').value?.length > 0 || this.form.get('diverses_info_client').value?.length > 0) {
      return false;
    } else {
      return true;
    }
  }

  submit(): void {
    if (this.form.valid) {
			this.loaderService.show();
			this.employeeService.updateExtraInfo(this.form.getRawValue(), this.id).subscribe(response => {
				this.loaderService.hide();
				this.snackBar.open(this.translateService.instant('general.messages.info_saved'));
				this.dialogRef.close(true);
			}, error => {
				this.snackBar.open('general.errors.standard');
				this.loaderService.hide();
			});
		}
  }

  close(): void {
    this.dialogRef.close();
  }
}
