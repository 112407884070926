import { Routes } from '@angular/router';
import {AppAdminGuard} from 'src/app/services/shared/app-admin.guard';
import {AppAdminCountryGuard} from 'src/app/services/shared/app-admincountry.guard';
import {AppSuperadminGuard} from 'src/app/services/shared/app-superadmin.guard';
import { AppLoggedInGuard } from '../../services/shared/app-logged-in.guard';
import { authRoutesNames } from './../auth/auth.routes.names';
import { coreRoutesNames } from './core.routes.names';

export const routes: Routes = [
	{
		path: coreRoutesNames.MAIN,
		loadChildren: () => import('../main/main.module')
			.then(m => m.MainModule),
		canActivate: [AppLoggedInGuard, AppAdminGuard]
	},
	{
		path: coreRoutesNames.AUTH,
		loadChildren: () => import('../auth/auth.module')
			.then(m => m.AuthModule)
	},
	{
		path: coreRoutesNames.DASHBOARD,
		loadChildren: () => import('../dashboard/dashboard.module')
			.then(m => m.DashboardModule),
			canActivate: [AppLoggedInGuard, AppAdminGuard]
	},
	{
		path: coreRoutesNames.EMPLOYEES,
		loadChildren: () => import('../employees/employees.module')
			.then(m => m.EmployeesModule),
			canActivate: [AppLoggedInGuard, AppAdminGuard]
	},
	{
		path: coreRoutesNames.RESIDENCES,
		loadChildren: () => import('../residences/residences.module')
			.then(m => m.ResidencesModule),
			canActivate: [AppLoggedInGuard, AppAdminGuard]
	},
	{
		path: coreRoutesNames.NOTIFICATIONS,
		loadChildren: () => import('../notifications/notifications.module')
			.then(m => m.NotificationsModule),
			canActivate: [AppLoggedInGuard, AppAdminGuard]
	},
	{
		path: coreRoutesNames.HELP,
		loadChildren: () => import('../help/help.module')
			.then(m => m.HelpModule),
			canActivate: [AppLoggedInGuard, AppAdminCountryGuard]
	},
	{
		path: coreRoutesNames.DASHBOARD,
		loadChildren: () => import('../help/help.module')
			.then(m => m.HelpModule),
			canActivate: [AppLoggedInGuard, AppAdminGuard]
	},
	{
		path: coreRoutesNames.ADMINISTRATORS,
		loadChildren: () => import('../administrators/administrators.module')
			.then(m => m.AdministratorsModule),
			canActivate: [AppLoggedInGuard, AppSuperadminGuard]
	},
	{
		path: coreRoutesNames.COUNTRIES,
		loadChildren: () => import('../countries/countries.module')
			.then(m => m.CountriesModule),
			canActivate: [AppLoggedInGuard, AppSuperadminGuard]
	},
	{
		path: coreRoutesNames.DOCUMENTS,
		loadChildren: () => import('../documents/documents.module')
			.then(m => m.DocumentsModule),
			canActivate: [AppLoggedInGuard, AppSuperadminGuard]
	},
	{ path: '**', redirectTo: coreRoutesNames.AUTH + '/' + authRoutesNames.LOGIN, pathMatch: 'full' }
];
