import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { User } from 'src/app/models/user';
import { UserResult } from 'src/app/models/user-result';
import { endpoints } from 'src/environments/endpoints';
import { environment } from 'src/environments/environment';

@Injectable({
	providedIn: 'root'
})
export class EmployeeService {

	constructor(private http: HttpClient) {
	}

	getByCountry(countryId: number): Observable<UserResult[]> {
		return this.http.get(environment.api.host + endpoints.employeesByCountry + '/' + countryId).pipe(
			map((res: any) => res.data.map(user => new UserResult(user))),
			catchError(this.handleError)
		);
	}

	getByAdminCountry(): Observable<UserResult[]> {
		return this.http.get(environment.api.host + endpoints.employees).pipe(
			map((res: any) => res.data.map(user => new UserResult(user))),
			catchError(this.handleError)
		);
	}

	getByPerimeter(perimeter_ids: number[]): Observable<UserResult[]> {
		return this.http.post(environment.api.host + endpoints.employeesByPerimeter, { perimeter_ids }).pipe(
			map((res: any) => res.data[0].map(user => new UserResult(user))),
			catchError(this.handleError)
		);
	}

	getByUserRole(user: User): Observable<UserResult[]> {
		if (user.isAdmin()) {
			return this.getByAdminCountry();
		}

		if (user.isSuperAdmin()) {
			const perimeter_ids: number[] = Object.values(JSON.parse(localStorage.getItem('perimeter_ids')));
			return this.getByPerimeter(perimeter_ids);
		}
	}

	getCountByUserRole(user: User): Observable<any> {
		if (user.isAdmin()) {
			return this.getCountByAdminCountry();
		}

		if (user.isSuperAdmin()) {
			const perimeter_ids: number[] = Object.values(JSON.parse(localStorage.getItem('perimeter_ids')));
			return this.getCountByPerimeters(perimeter_ids);
		}
	}

	getCountByAdminCountry(): Observable<any> {
		return this.http.get(environment.api.host + endpoints.employeesCount).pipe(
			catchError(this.handleError)
		);
	}

	getCountByPerimeters(perimeter_ids: number[]): Observable<any> {
		return this.http.post(environment.api.host + endpoints.employeesByPerimeter + '/count', { perimeter_ids }).pipe(
			catchError(this.handleError)
		);
	}

	updateAssistance(data: any, userId: number) {
		return this.http.put(environment.api.host + endpoints.employees + '/' + userId + '/assistance', data).pipe(
			catchError(this.handleError)
		);
	}

	updateIsInCountryByAdmin(personId: number, isInsideCountry): Observable<any> {
		return this.http.put(environment.api.host + endpoints.employees + '/' + personId + '/inside-country', { is_inside_country: isInsideCountry }).pipe(
			catchError(this.handleError)
		);
	}

	unlockAssistance(userId: number) {
		return this.http.put(environment.api.host + endpoints.employees + '/' + userId + '/assistance/unlock', {}).pipe(
			catchError(this.handleError)
		);
	}

	delete(userId: number): Observable<any> {
		return this.http.delete(environment.api.host + endpoints.employees + '/' + userId).pipe(
			catchError(this.handleError)
		);
	}

	export(person_info_ids: number[]): Observable<Blob> {

		let perimeter_ids: number[] = [];

		//if (user.isSuperAdmin()) {
			perimeter_ids = Object.values(JSON.parse(localStorage.getItem('perimeter_ids')));
		//}

		return this.http.post(environment.api.host + endpoints.employeesExport,
			{ person_info_ids ,perimeter_ids},
			{ responseType: 'blob' }
		).pipe(
			map(res => res),
			catchError(this.handleError)
		);
	}

	filter(filter_data: any): Observable<UserResult[]> {
		return this.http.post(environment.api.host + endpoints.employees + '/filters', filter_data).pipe(
			map((res: any) => res.data[0].map(user => new UserResult(user))),
			catchError(this.handleError)
		);
	}

	protected handleError(error: any): Promise<any> {
		if (error.status === 401) {
			window.location.reload();
		}

		return Promise.reject(error?.error?.message || error?.message || error);
	}

  getPersonById(id: number): Observable<any> {
		return this.http.get(environment.api.host + endpoints.employees + '/' + id).pipe(
			map(res => res),
			catchError(this.handleError)
		);
	}

  updateExtraInfo(data: any, id: number) {
		return this.http.put(environment.api.host + endpoints.employees + '/' + id, data).pipe(
			catchError(this.handleError)
		);
	}
}
