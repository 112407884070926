<div class="dialog-wrapper" [formGroup]="form">
  <div mat-dialog-title class="mb-5 d-flex align-items-center">
    <h1>{{ "user_search.dialog_extra_info.title" | translate}}</h1>
  </div>
  <div mat-dialog-content class="text-center">
    <mat-form-field class="w-75">
      <mat-label>{{ "user_search.dialog_extra_info.compositions" | translate}}</mat-label>
      <textarea matInput [placeholder]="'notifications.message_creation.messagePH' | translate" rows="6" formControlName="compositions"></textarea>
    </mat-form-field>
    <mat-form-field class="w-75">
      <mat-label>{{ "user_search.dialog_extra_info.addresses" | translate}}</mat-label>
      <textarea matInput [placeholder]="'notifications.message_creation.messagePH' | translate" rows="6" formControlName="addresses"> </textarea>
    </mat-form-field>
    <mat-form-field class="w-75">
      <mat-label>{{ "user_search.dialog_extra_info.trips" | translate}}</mat-label>
      <textarea matInput [placeholder]="'notifications.message_creation.messagePH' | translate" rows="6" formControlName="trips"> </textarea>
    </mat-form-field>
    <mat-form-field class="w-75">
      <mat-label>{{ "user_search.dialog_extra_info.diverses_info" | translate}}</mat-label>
      <textarea matInput [placeholder]="'notifications.message_creation.messagePH' | translate" rows="6" formControlName="diverses_info"> </textarea>
    </mat-form-field>
    <mat-form-field class="w-75">
      <mat-label>{{ "user_search.dialog_extra_info.other_infos" | translate}}</mat-label>
      <textarea matInput [placeholder]="'notifications.message_creation.messagePH' | translate" rows="6" formControlName="diverses_info_client"> </textarea>
    </mat-form-field>
  </div>
  <br />
  <div mat-dialog-actions>
    <div class="w-100 text-center">
      <div>
        <button
          mat-raised-button
          color="primary"
          (click)="submit()"
          class="mr-3"
        >
          <mat-icon>arrow_right</mat-icon>
          <span>{{ "general.validate" | translate }}</span>
          <!-- <span *ngIf="isBtnValid">{{ "general.edit" | translate }}</span>-->
        </button>
        <button mat-stroked-button color="primary" (click)="close()">
          <mat-icon>arrow_right</mat-icon>
          {{ "general.cancel" | translate }}
        </button>
      </div>
      <br />
    </div>
  </div>
</div>
