import {HttpClient} from '@angular/common/http';
import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {endpoints} from 'src/environments/endpoints';
import {environment} from 'src/environments/environment';
import {Country} from '../models/country';
import {User} from '../models/user';

@Injectable({
  providedIn: 'root'
})
export class AdministratorService {

	constructor(private http: HttpClient) { }

	getForPerimeters(): Observable<Country[]> {
		// tslint:disable-next-line: radix
		const perimeter_ids: number[] = Object.values(JSON.parse(localStorage.getItem('perimeter_ids')));

		return this.http.post(environment.api.host + endpoints.administratorsForPerimeter, { perimeter_ids }).pipe(
			map((res: any) => res.data.map(country => new Country(country))),
			catchError(this.handleError)
		);
	}

	getSuperAdmins(): Observable<User[]> {
		return this.http.get(environment.api.host + endpoints.administratorsSuperadmins).pipe(
			map((res: any) => res.data.map(user => new User(user))),
			catchError(this.handleError)
		);
	}

	addAdmin(data: any): Observable<any> {
		return this.http.post(environment.api.host + endpoints.administrators, data).pipe(
			catchError(this.handleError)
		);
	}

	addSuperAdmin(data: any): Observable<any> {
		return this.http.post(environment.api.host + endpoints.administratorsSuperadmins, data).pipe(
			catchError(this.handleError)
		);
	}
    
    saveSearchFilters(data: any): Observable<any> {
        return this.http.post(environment.api.host + endpoints.adminSearchFilters, data).pipe(
			map(
				(res: any) => res.data.user
			),
			catchError(this.handleError)
		);
    }
    
    loginWithOtherAccount(id: number): Observable<any> {
        return this.http.post(environment.api.host + endpoints.employeesLogin, {id}).pipe(
			catchError(this.handleError)
		);
    }
    
    deleteSavedSearch(id: number): Observable<any> {
        return this.http.delete(environment.api.host + endpoints.adminSearchFilters + '/' + id).pipe(
			catchError(this.handleError)
		);
    }

	deleteAdmin(userId: number, countryId: number): Observable<any> {
		return this.http.delete(environment.api.host + endpoints.administrators + '/' + userId + '/countries/' + countryId).pipe(
			catchError(this.handleError)
		);
	}
    
    deleteSuperAdmin(userId: number): Observable<any> {
		return this.http.delete(environment.api.host + endpoints.administrators + '/' + userId).pipe(
			catchError(this.handleError)
		);
	}

	protected handleError(error: any): Promise<any> {
		if (error.status === 401) {
			window.location.reload();
		}

		return Promise.reject(error?.error?.message || error?.message || error);
	}
}
