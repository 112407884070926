import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LanguagesService {

  langs: any[];
  docsLangs: any[];

  constructor(private http: HttpClient) {
    // TODO: get translateService available langs (?)
    this.langs = [
      { code: 'en', label: 'EN', fullName: 'English' },
      { code: 'fr', label: 'FR', fullName: 'Français' }
    ];

    this.docsLangs = [
      { code: 'en', label: 'EN', fullName: 'English' },
      { code: 'fr', label: 'FR', fullName: 'Français' },
      { code: 'es', label: 'ES', fullName: 'Español' },
      { code: 'pt', label: 'PT', fullName: 'Português' }
    ];
  }
}
