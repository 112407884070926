import { Component, Inject, OnInit } from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {FormGroup} from '@angular/forms';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {LoaderService} from '@ff/loader';
import {TranslateService} from '@ngx-translate/core';
import {User} from 'src/app/models/user';
import {UserResult} from 'src/app/models/user-result';
import {EmployeeService} from 'src/app/services/user/employee.service';
import {DialogPerimeterComponent} from '../dialog-perimeter/dialog-perimeter.component';

@Component({
  selector: 'app-dialog-assistance',
  templateUrl: './dialog-assistance.component.html',
  styleUrls: ['./dialog-assistance.component.scss']
})
export class DialogAssistanceComponent implements OnInit {
    form: FormGroup;
    user: UserResult;

    constructor(
        private fb: FormBuilder,
		private snackBar: MatSnackBar,
        private loaderService: LoaderService,
        private employeeService: EmployeeService,
        private translateService: TranslateService,
        public dialogRef: MatDialogRef<DialogPerimeterComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) { }

    ngOnInit(): void {
        this.user = this.data.user;
        this.initForm();
    }
    
    initForm(): void {
		this.form = this.fb.group({
			assistance_group: [this.user.assistance_group, [Validators.required]],
		});
	}
    
    submit(): void {
		if (this.form.valid) {
			this.loaderService.show();
			this.employeeService.updateAssistance(this.form.getRawValue(), this.user.id).subscribe(response => {
				this.loaderService.hide();
				this.snackBar.open(this.translateService.instant('general.messages.info_saved'));
				this.dialogRef.close(true);
			}, error => {
				this.snackBar.open(error);
				this.loaderService.hide();
			});
		}
	}

	close(): void {
		this.dialogRef.close();
	}
}
